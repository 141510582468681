<section class="_live_programme">   
    <div class="_inner_prog">

        <!-- Live Days programme -->
        <div class="_p_live_days">
            <div class="owl-carousel _live_days owl-theme">
                <owl-carousel-o [options]="days_owlOption">
                    <ng-container *ngFor="let item of epgPrograms; let i=index">
                    <ng-template carouselSlide>
                        
                        <div class="item">
                            <div class="_parent_days_frame">
                                <div class="days_flex" (click)="setEpgDataDayWise(item.day)">
                                    <a [innerHTML]="item.day | ordinalDate"  [ngClass]="{'active': item.day == activeDay }" href="javascript:;">
                                    </a>
                                </div>                           
                            </div>
                        </div>

                    </ng-template>
                </ng-container>
                </owl-carousel-o>
            </div>   
    </div>

        <!--End Live Days programme -->

        <!-- Live Time programme -->
 
        <!-- [ngStyle]="{'width': '500 px !important'}" -->
    <div class="_p_live_time">               
        <!-- {{epgDataByDay | json}} -->
        <!-- *ngIf="epgPrograms.length > 0" -->
        <div class="owl-carousel _live_time owl-theme" >
            <owl-carousel-o [options]="time_owlOption" #owlElement>
                <ng-container  *ngFor="let item of epgDataByDay; let i=index; trackBy: trackByFn" > 
                <ng-template carouselSlide [id]="i" [width]="(item.duration<30?120:item.duration*4)" >
                    <!-- [ngStyle]="{'width':item.duration ==30?time_frame_width:(time_frame_width*2)+'px'}" -->
                    <!-- [ngClass]="{'thirty_minClass': item.duration == '30', 'sixty_minClass':item.duration == '60' }" -->
                    <!-- [ngStyle]="{'width': item.duration*5} +'px'"  -->
                    <div class="item" id="timeslider{{i}}" [ngClass]="{'time_less':item.duration < '30'}" >
                        <div class="_parent_time_frame"  [ngClass]="{'current': i == activeProgramIndex, 'time_less':item.duration < '30'}" >
                            <div class="_t_frame">
                                <div class="_inner_t_frame">
                                    <p>{{ set_moment(item.start_timestamp) }}</p>
                                    <!-- <p>{{ set_moment(item.end_timestamp) }}</p> -->
                                </div>
                                <div class="_programme_title">
                                    
                                    <div class="_title_split">
                                        <!-- <h4>{{item.title | slice:0:10}}</h4> -->
                                        <h4>{{item.title }}</h4>
                                        <div class="_s_e_time">
                                            <p>
                                                {{ set_moment(item.start_timestamp) }}                                        
                                            </p>
                                            <p class="_hypens">-</p>
                                            <p>
                                                {{ set_moment(item.end_timestamp) }}                                        
                                            </p>
                                        </div>                                       
                                    </div>
                                </div>                               
                            </div> 
                            <div class="_slider_arrow" id="slider_arrow{{i}}" [ngStyle]="{'width': time_frame_width+'px'}" >
                                <div class="_auto_live" id="auto_live{{i}}">
                                    <span></span>
                               </div>
                            </div>                                                        
                        </div>
                    </div>

                </ng-template>
                
            </ng-container>
            </owl-carousel-o>
        </div> 
    </div>                                     

        <!--End Live Time programme -->
    </div> 
 </section>