import { CatlougeTypeConstants } from "src/app/core/constants/userType.constant";

    export abstract class tempLiveData {
    public static readonly egpData = {
      "error_code": 200,
      "last_updated_time": "0",
      "error_string": "Your = updated epg record is following",
      "result": [
          {
              "_id": "5fd00fc4c8d1452ea066bd34",
              "title": "Raja, Rasoi Aur Anya Kahaniyaan",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "12:00:00",
              "duration": 30,
              "synopsis": "Food enthusiasts provide information about the local cuisines in several regions of India and the extravagant culinary traditions of Indian royalty.",
              "progdate": "2020-12-09",
              "image_public_id": "E3B25A5AE2F06C67B8791A9D74BAF5492F8274EB4",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549975664",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607495400,
              'current_timestamp':1607492300,
              "end_timestamp": 1607497200,
              "isReminded": "0",
              "epg_id": "5fd00fc4c8d1452ea066bd34",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fc5c8d1452ea066bd35",
              "title": "Safarnama",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "12:30:00",
              "duration": 30,
              "synopsis": "Ankit Siwach visits some of the unexplored destinations in India and provides an insight into their culture, heritage, cuisine and natural beauty.",
              "progdate": "2020-12-09",
              "image_public_id": "9CFDB95A1E9205AE131C497A4E7ABDBA7C87343CN",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550757067",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607497200,
              "end_timestamp": 1607499000,
              "isReminded": "0",
              "epg_id": "5fd00fc5c8d1452ea066bd35",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fc7c8d1452ea066bd36",
              "title": "Raja, Rasoi Aur Anya Kahaniyaan",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "13:00:00",
              "duration": 60,
              "synopsis": "Food enthusiasts provide information about the local cuisines in several regions of India and the extravagant culinary traditions of Indian royalty.",
              "progdate": "2020-12-09",
              "image_public_id": "89EC80BEE3B2801696C9EEFDC63F6E572D7CDCF8L",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549975664",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607499000,
              "end_timestamp": 1607502600,
              "isReminded": "0",
              "epg_id": "5fd00fc7c8d1452ea066bd36",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fc8c8d1452ea066bd37",
              "title": "Lost Recipes",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "14:00:00",
              "duration": 60,
              "synopsis": "The host journeys across various regions of India to revive and popularise forgotten traditional recipes.",
              "progdate": "2020-12-09",
              "image_public_id": "C47252111FDE5CA66058ACD015F79FA0F7FE240A3",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550000138",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607502600,
              "end_timestamp": 1607506200,
              "isReminded": "0",
              "epg_id": "5fd00fc8c8d1452ea066bd37",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fc9c8d1452ea066bd38",
              "title": "Raja, Rasoi Aur Andaaz Anokha",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "15:00:00",
              "duration": 30,
              "synopsis": "Ranveer Brar, an Indian chef, shares the recipes of a variety of mouthwatering dishes and demonstrates the steps involved in their preparation.",
              "progdate": "2020-12-09",
              "image_public_id": "DF6CCD63DC97F3153A604F8A7E565193F22AA3B9L",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550352928",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607506200,
              "end_timestamp": 1607508000,
              "isReminded": "0",
              "epg_id": "5fd00fc9c8d1452ea066bd38",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fcbc8d1452ea066bd39",
              "title": "Tyohar Ki Thaali",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "15:30:00",
              "duration": 30,
              "synopsis": "The host shares recipes of dishes associated with various festivals that are celebrated in India. She also communicates tales of legends and myths from the rich Indian folklore.",
              "progdate": "2020-12-09",
              "image_public_id": "2F23BBF07222570B27CFBBAE57107E9AC10D42D73",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550368466",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607508000,
              "end_timestamp": 1607509800,
              "isReminded": "0",
              "epg_id": "5fd00fcbc8d1452ea066bd39",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fccc8d1452ea066bd3a",
              "title": "Wild Wild India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "16:00:00",
              "duration": 30,
              "synopsis": "The host explores the state of India's wildlife, the animal rights activists and conservationists who do their best to support it as well as the various threats these animals face.",
              "progdate": "2020-12-09",
              "image_public_id": "FBBDAA1096BA3CC402492032B5F14AFFE7F4CA00W",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550662474",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607509800,
              "end_timestamp": 1607511600,
              "isReminded": "0",
              "epg_id": "5fd00fccc8d1452ea066bd3a",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fcdc8d1452ea066bd3b",
              "title": "Ekaant",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "16:30:00",
              "duration": 30,
              "synopsis": "The host explores abandoned historical spots in India that are filled with mystery and studies how these once-flourishing places came to be in ruins.",
              "progdate": "2020-12-09",
              "image_public_id": "460ED4562E6E02F562F2DD1CA4694004640DBA76P",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549976895",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607511600,
              "end_timestamp": 1607513400,
              "isReminded": "0",
              "epg_id": "5fd00fcdc8d1452ea066bd3b",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fcec8d1452ea066bd3c",
              "title": "Sanrachna",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "17:00:00",
              "duration": 30,
              "synopsis": "The tradition of gathering data using astronomy leads the team to travel to the Konark Sun Temple in Orissa and later to the Jantar Mantar in Jaipur.",
              "progdate": "2020-12-09",
              "image_public_id": "CE5AF3DF118092A96DB36175401260556215ABE4H",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549982646",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607513400,
              "end_timestamp": 1607515200,
              "isReminded": "0",
              "epg_id": "5fd00fcec8d1452ea066bd3c",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fd0c8d1452ea066bd3d",
              "title": "Kahi Suni",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "17:30:00",
              "duration": 30,
              "synopsis": "Host Sara Khan travels the length and breadth of India and explores the established myths and legends. She tries to decode them and discovers their origin and the reasons they are prevalent.",
              "progdate": "2020-12-09",
              "image_public_id": "C810C19B0642BFAA510E321C1AF3D3BF7BCD42E59",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549976894",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607515200,
              "end_timestamp": 1607517000,
              "isReminded": "0",
              "epg_id": "5fd00fd0c8d1452ea066bd3d",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fd1c8d1452ea066bd3e",
              "title": "Adrishya",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "18:00:00",
              "duration": 60,
              "synopsis": "The filmmaker explores the lives of India's greatest spies, the dangers they faced as they executed their missions and how they risked their lives for national honour.",
              "progdate": "2020-12-09",
              "image_public_id": "A4B4473EDC79B352E90536A329B5CCF656BA26B3B",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549975676",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607517000,
              "end_timestamp": 1607520600,
              "isReminded": "0",
              "epg_id": "5fd00fd1c8d1452ea066bd3e",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fd2c8d1452ea066bd3f",
              "title": "Raja, Rasoi Aur Anya Kahaniyaan",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "19:00:00",
              "duration": 30,
              "synopsis": "Food enthusiasts provide information about the local cuisines in several regions of India and the extravagant culinary traditions of Indian royalty.",
              "progdate": "2020-12-09",
              "image_public_id": "E3B25A5AE2F06C67B8791A9D74BAF5492F8274EB4",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549975664",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607520600,
              "end_timestamp": 1607522400,
              "isReminded": "0",
              "epg_id": "5fd00fd2c8d1452ea066bd3f",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fd3c8d1452ea066bd40",
              "title": "Lost Recipes",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "19:30:00",
              "duration": 30,
              "synopsis": "The host journeys across various regions of India to revive and popularise forgotten traditional recipes.",
              "progdate": "2020-12-09",
              "image_public_id": "C9E8CD2B80A403E37F00C849A3C469BFAA2F8EAF4",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550000138",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607522400,
              "end_timestamp": 1607524200,
              "isReminded": "0",
              "epg_id": "5fd00fd3c8d1452ea066bd40",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fd5c8d1452ea066bd41",
              "title": "Raja, Rasoi Aur Anya Kahaniyaan",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "20:00:00",
              "duration": 60,
              "synopsis": "Food enthusiasts provide information about the local cuisines in several regions of India and the extravagant culinary traditions of Indian royalty.",
              "progdate": "2020-12-09",
              "image_public_id": "89EC80BEE3B2801696C9EEFDC63F6E572D7CDCF8L",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549975664",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607524200,
              "end_timestamp": 1607527800,
              "isReminded": "0",
              "epg_id": "5fd00fd5c8d1452ea066bd41",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fd6c8d1452ea066bd42",
              "title": "Tyohar Ki Thaali",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "21:00:00",
              "duration": 30,
              "synopsis": "The host shares recipes of dishes associated with various festivals that are celebrated in India. She also communicates tales of legends and myths from the rich Indian folklore.",
              "progdate": "2020-12-09",
              "image_public_id": "0D1266F052CE83B77FB5CD268FD7375630A57BADR",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550368466",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607527800,
              "end_timestamp": 1607529600,
              "isReminded": "0",
              "epg_id": "5fd00fd6c8d1452ea066bd42",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fd7c8d1452ea066bd43",
              "title": "Wild Wild India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "21:30:00",
              "duration": 30,
              "synopsis": "The host explores the state of India's wildlife, the animal rights activists and conservationists who do their best to support it as well as the various threats these animals face.",
              "progdate": "2020-12-09",
              "image_public_id": "FBBDAA1096BA3CC402492032B5F14AFFE7F4CA00W",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550662474",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607529600,
              "end_timestamp": 1607531400,
              "isReminded": "0",
              "epg_id": "5fd00fd7c8d1452ea066bd43",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fd8c8d1452ea066bd44",
              "title": "Rakkt",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "22:00:00",
              "duration": 30,
              "synopsis": "Major events, wars, crimes and assassinations that changed the course of India's history and shaped its current society are brought into focus.",
              "progdate": "2020-12-09",
              "image_public_id": "16FC9900F290642D88F0E5D221A3E781C45FB86D0",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549976896",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607531400,
              "end_timestamp": 1607533200,
              "isReminded": "0",
              "epg_id": "5fd00fd8c8d1452ea066bd44",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fd9c8d1452ea066bd45",
              "title": "Rakkt",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "22:30:00",
              "duration": 30,
              "synopsis": "Major events, wars, crimes and assassinations that changed the course of India's history and shaped its current society are brought into focus.",
              "progdate": "2020-12-09",
              "image_public_id": "D73714C7EC05334952805ACFB99D3F1C2B931D51O",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549976896",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607533200,
              "end_timestamp": 1607535000,
              "isReminded": "0",
              "epg_id": "5fd00fd9c8d1452ea066bd45",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fdbc8d1452ea066bd46",
              "title": "Epic Ke Dus",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "23:00:00",
              "duration": 60,
              "synopsis": "Experts share their views on ten thoroughly researched people, places or events that changed the course of history in India.",
              "progdate": "2020-12-09",
              "image_public_id": "B519897669A2F35837374FCED9F449831E67D23A0",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549975673",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607535000,
              "end_timestamp": 1607538600,
              "isReminded": "0",
              "epg_id": "5fd00fdbc8d1452ea066bd46",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fdcc8d1452ea066bd47",
              "title": "Indipedia",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "00:00:00",
              "duration": 30,
              "synopsis": "The host travels to different states in India and learns more about the customs and traditions that have been a part of the country since ancient times.",
              "progdate": "2020-12-10",
              "image_public_id": "9C1F3640E08B92934560421D3773AAF2EAB503090",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550375314",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607538600,
              "end_timestamp": 1607540400,
              "isReminded": "0",
              "epg_id": "5fd00fdcc8d1452ea066bd47",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fddc8d1452ea066bd48",
              "title": "Sanrachna",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "00:30:00",
              "duration": 30,
              "synopsis": "Host Vaibhavi travels across India visiting the famous historical and architectural monuments, discovering the secrets behind the existence and structure of these engineering marvels.",
              "progdate": "2020-12-10",
              "image_public_id": "CE5AF3DF118092A96DB36175401260556215ABE4H",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549982646",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607540400,
              "end_timestamp": 1607542200,
              "isReminded": "0",
              "epg_id": "5fd00fddc8d1452ea066bd48",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fdec8d1452ea066bd49",
              "title": "Wild Wild India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "01:00:00",
              "duration": 30,
              "synopsis": "The host explores the state of India's wildlife, the animal rights activists and conservationists who do their best to support it as well as the various threats these animals face.",
              "progdate": "2020-12-10",
              "image_public_id": "FBBDAA1096BA3CC402492032B5F14AFFE7F4CA00W",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550662474",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607542200,
              "end_timestamp": 1607544000,
              "isReminded": "0",
              "epg_id": "5fd00fdec8d1452ea066bd49",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fe0c8d1452ea066bd4a",
              "title": "Epic Ke Dus",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "01:30:00",
              "duration": 60,
              "synopsis": "Experts share their views on ten thoroughly researched people, places or events that changed the course of history in India.",
              "progdate": "2020-12-10",
              "image_public_id": "B519897669A2F35837374FCED9F449831E67D23A0",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549975673",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607544000,
              "end_timestamp": 1607547600,
              "isReminded": "0",
              "epg_id": "5fd00fe0c8d1452ea066bd4a",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fe1c8d1452ea066bd4b",
              "title": "Ekaant",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "02:30:00",
              "duration": 30,
              "synopsis": "The host explores abandoned historical spots in India that are filled with mystery and studies how these once-flourishing places came to be in ruins.",
              "progdate": "2020-12-10",
              "image_public_id": "6AC21C167B19E54A3ACB4816D3ED61ADD341BCF5H",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549976895",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607547600,
              "end_timestamp": 1607549400,
              "isReminded": "0",
              "epg_id": "5fd00fe1c8d1452ea066bd4b",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fe2c8d1452ea066bd4c",
              "title": "Kahi Suni",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "03:00:00",
              "duration": 30,
              "synopsis": "In the modern-day Kurukshetra, Sara explores the story of 'Geeta Saar', the dialogue between Krishna and Arjuna when the latter is faced with self-doubt.",
              "progdate": "2020-12-10",
              "image_public_id": "C810C19B0642BFAA510E321C1AF3D3BF7BCD42E59",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549976894",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607549400,
              "end_timestamp": 1607551200,
              "isReminded": "0",
              "epg_id": "5fd00fe2c8d1452ea066bd4c",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fe3c8d1452ea066bd4d",
              "title": "Sanrachna",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "03:30:00",
              "duration": 30,
              "synopsis": "The tradition of gathering data using astronomy leads the team to travel to the Konark Sun Temple in Orissa and later to the Jantar Mantar in Jaipur.",
              "progdate": "2020-12-10",
              "image_public_id": "CE5AF3DF118092A96DB36175401260556215ABE4H",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549982646",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607551200,
              "end_timestamp": 1607553000,
              "isReminded": "0",
              "epg_id": "5fd00fe3c8d1452ea066bd4d",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fe5c8d1452ea066bd4e",
              "title": "Adrishya",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "04:00:00",
              "duration": 60,
              "synopsis": "Posing as an ISI agent, Ajit Doval entered the Harmandir Sahib and is known to have lived with the enemy for over two months, negotiating with the militants to surrender.",
              "progdate": "2020-12-10",
              "image_public_id": "A4B4473EDC79B352E90536A329B5CCF656BA26B3B",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549975676",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607553000,
              "end_timestamp": 1607556600,
              "isReminded": "0",
              "epg_id": "5fd00fe5c8d1452ea066bd4e",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fe6c8d1452ea066bd4f",
              "title": "Sharanam",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "05:00:00",
              "duration": 30,
              "synopsis": "The host speaks about religiously important locations across the country and presents a compilation of videos in which pilgrims share their experiences of visiting different holy sites.",
              "progdate": "2020-12-10",
              "image_public_id": "3200C1167109A987A5C9BE22634EB7C7128882A1A",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550407318",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607556600,
              "end_timestamp": 1607558400,
              "isReminded": "0",
              "epg_id": "5fd00fe6c8d1452ea066bd4f",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fe7c8d1452ea066bd50",
              "title": "Buddha Sutra",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "05:30:00",
              "duration": 30,
              "synopsis": "The narrator presents an interesting account of Buddha's journey, his teachings and its widespread impact on art, architecture and politics.",
              "progdate": "2020-12-10",
              "image_public_id": "09E2C833EE5D69C6A3FB832326909D7D9C2A6F84W",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550699244",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607558400,
              "end_timestamp": 1607560200,
              "isReminded": "0",
              "epg_id": "5fd00fe7c8d1452ea066bd50",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fe9c8d1452ea066bd51",
              "title": "Epicgrams",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "06:00:00",
              "duration": 30,
              "synopsis": "The host presents fascinating facets of Indian history related to personalities in various fields in a unique manner that speaks to younger audiences.",
              "progdate": "2020-12-10",
              "image_public_id": "892966E5CCEFB0A3CC039FA90CCA4B3C091F99C9K",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550050270",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607560200,
              "end_timestamp": 1607562000,
              "isReminded": "0",
              "epg_id": "5fd00fe9c8d1452ea066bd51",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00feac8d1452ea066bd52",
              "title": "Made In India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "06:30:00",
              "duration": 30,
              "synopsis": "Experts discuss the history of the remarkable inventions that originated in India and explain the significance of the country's contributions to the world.",
              "progdate": "2020-12-10",
              "image_public_id": "AD9EB41D0AEE540A4F6EFD1B0EDCD40BAA29428A4",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549976897",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607562000,
              "end_timestamp": 1607563800,
              "isReminded": "0",
              "epg_id": "5fd00feac8d1452ea066bd52",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00febc8d1452ea066bd53",
              "title": "Paid Programming",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "07:00:00",
              "duration": 30,
              "synopsis": "The host presents a wide selection of products which can be bought through a convenient method of shopping from the comfort of home.",
              "progdate": "2020-12-10",
              "image_public_id": "9D33A1EE8560890BAE9EA52177EA96E1148C2BEF3",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550161407",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607563800,
              "end_timestamp": 1607565600,
              "isReminded": "0",
              "epg_id": "5fd00febc8d1452ea066bd53",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fecc8d1452ea066bd54",
              "title": "Indipedia",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "07:30:00",
              "duration": 30,
              "synopsis": "The host travels to different states in India and learns more about the customs and traditions that have been a part of the country since ancient times.",
              "progdate": "2020-12-10",
              "image_public_id": "9C1F3640E08B92934560421D3773AAF2EAB503090",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550375314",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607565600,
              "end_timestamp": 1607567400,
              "isReminded": "0",
              "epg_id": "5fd00fecc8d1452ea066bd54",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fedc8d1452ea066bd55",
              "title": "Sharanam",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "08:00:00",
              "duration": 30,
              "synopsis": "The host speaks about religiously important locations across the country and presents a compilation of videos in which pilgrims share their experiences of visiting different holy sites.",
              "progdate": "2020-12-10",
              "image_public_id": "3200C1167109A987A5C9BE22634EB7C7128882A1A",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550407318",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607567400,
              "end_timestamp": 1607569200,
              "isReminded": "0",
              "epg_id": "5fd00fedc8d1452ea066bd55",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fefc8d1452ea066bd56",
              "title": "Ekaant",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "08:30:00",
              "duration": 30,
              "synopsis": "The host explores abandoned historical spots in India that are filled with mystery and studies how these once-flourishing places came to be in ruins.",
              "progdate": "2020-12-10",
              "image_public_id": "6AC21C167B19E54A3ACB4816D3ED61ADD341BCF5H",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549976895",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607569200,
              "end_timestamp": 1607571000,
              "isReminded": "0",
              "epg_id": "5fd00fefc8d1452ea066bd56",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00ff0c8d1452ea066bd57",
              "title": "Raja, Rasoi Aur Anya Kahaniyaan",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "09:00:00",
              "duration": 60,
              "synopsis": "Food enthusiasts provide information about the local cuisines in several regions of India and the extravagant culinary traditions of Indian royalty.",
              "progdate": "2020-12-10",
              "image_public_id": "89EC80BEE3B2801696C9EEFDC63F6E572D7CDCF8L",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549975664",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607571000,
              "end_timestamp": 1607574600,
              "isReminded": "0",
              "epg_id": "5fd00ff0c8d1452ea066bd57",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00ff1c8d1452ea066bd58",
              "title": "Epic Special",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "10:00:00",
              "duration": 60,
              "synopsis": "Various motorbike enthusiasts talk about their experience of riding a particular brand's bikes and shed light on its various features and history.",
              "progdate": "2020-12-10",
              "image_public_id": "B743A4C771484795F3B4557C0994CB206C9CC6FEB",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550522513",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607574600,
              "end_timestamp": 1607578200,
              "isReminded": "0",
              "epg_id": "5fd00ff1c8d1452ea066bd58",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00ff3c8d1452ea066bd59",
              "title": "Kahi Suni",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "11:00:00",
              "duration": 30,
              "synopsis": "Sara explores the legend of Baz Bahadur and Rani Roopmati who committed suicide to avoid being captured by Akbar's general, Adham Khan, after he took over Mandu.",
              "progdate": "2020-12-10",
              "image_public_id": "C810C19B0642BFAA510E321C1AF3D3BF7BCD42E59",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549976894",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607578200,
              "end_timestamp": 1607580000,
              "isReminded": "0",
              "epg_id": "5fd00ff3c8d1452ea066bd59",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00ff4c8d1452ea066bd5a",
              "title": "Ekaant",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "11:30:00",
              "duration": 30,
              "synopsis": "The host explores abandoned historical spots in India that are filled with mystery and studies how these once-flourishing places came to be in ruins.",
              "progdate": "2020-12-10",
              "image_public_id": "460ED4562E6E02F562F2DD1CA4694004640DBA76P",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549976895",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607580000,
              "end_timestamp": 1607581800,
              "isReminded": "0",
              "epg_id": "5fd00ff4c8d1452ea066bd5a",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00ff5c8d1452ea066bd5b",
              "title": "Raja, Rasoi Aur Anya Kahaniyaan",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "12:00:00",
              "duration": 30,
              "synopsis": "Food enthusiasts provide information about the local cuisines in several regions of India and the extravagant culinary traditions of Indian royalty.",
              "progdate": "2020-12-10",
              "image_public_id": "E3B25A5AE2F06C67B8791A9D74BAF5492F8274EB4",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549975664",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607581800,
              "end_timestamp": 1607583600,
              "isReminded": "0",
              "epg_id": "5fd00ff5c8d1452ea066bd5b",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00ff6c8d1452ea066bd5c",
              "title": "Safarnama",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "12:30:00",
              "duration": 30,
              "synopsis": "Ankit Siwach visits some of the unexplored destinations in India and provides an insight into their culture, heritage, cuisine and natural beauty.",
              "progdate": "2020-12-10",
              "image_public_id": "9CFDB95A1E9205AE131C497A4E7ABDBA7C87343CN",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550757067",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607583600,
              "end_timestamp": 1607585400,
              "isReminded": "0",
              "epg_id": "5fd00ff6c8d1452ea066bd5c",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00ff8c8d1452ea066bd5d",
              "title": "Raja, Rasoi Aur Anya Kahaniyaan",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "13:00:00",
              "duration": 60,
              "synopsis": "Food enthusiasts provide information about the local cuisines in several regions of India and the extravagant culinary traditions of Indian royalty.",
              "progdate": "2020-12-10",
              "image_public_id": "89EC80BEE3B2801696C9EEFDC63F6E572D7CDCF8L",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549975664",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607585400,
              "end_timestamp": 1607589000,
              "isReminded": "0",
              "epg_id": "5fd00ff8c8d1452ea066bd5d",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00ff9c8d1452ea066bd5e",
              "title": "Lost Recipes",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "14:00:00",
              "duration": 60,
              "synopsis": "The host journeys across various regions of India to revive and popularise forgotten traditional recipes.",
              "progdate": "2020-12-10",
              "image_public_id": "C47252111FDE5CA66058ACD015F79FA0F7FE240A3",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550000138",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607589000,
              "end_timestamp": 1607592600,
              "isReminded": "0",
              "epg_id": "5fd00ff9c8d1452ea066bd5e",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00ffac8d1452ea066bd5f",
              "title": "Raja, Rasoi Aur Andaaz Anokha",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "15:00:00",
              "duration": 30,
              "synopsis": "Ranveer Brar, an Indian chef, shares the recipes of a variety of mouthwatering dishes and demonstrates the steps involved in their preparation.",
              "progdate": "2020-12-10",
              "image_public_id": "DF6CCD63DC97F3153A604F8A7E565193F22AA3B9L",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550352928",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607592600,
              "end_timestamp": 1607594400,
              "isReminded": "0",
              "epg_id": "5fd00ffac8d1452ea066bd5f",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00ffbc8d1452ea066bd60",
              "title": "Tyohar Ki Thaali",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "15:30:00",
              "duration": 30,
              "synopsis": "The host shares recipes of dishes associated with various festivals that are celebrated in India. She also communicates tales of legends and myths from the rich Indian folklore.",
              "progdate": "2020-12-10",
              "image_public_id": "2F23BBF07222570B27CFBBAE57107E9AC10D42D73",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550368466",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607594400,
              "end_timestamp": 1607596200,
              "isReminded": "0",
              "epg_id": "5fd00ffbc8d1452ea066bd60",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00ffdc8d1452ea066bd61",
              "title": "Wild Wild India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "16:00:00",
              "duration": 30,
              "synopsis": "The host explores the state of India's wildlife, the animal rights activists and conservationists who do their best to support it as well as the various threats these animals face.",
              "progdate": "2020-12-10",
              "image_public_id": "FBBDAA1096BA3CC402492032B5F14AFFE7F4CA00W",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550662474",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607596200,
              "end_timestamp": 1607598000,
              "isReminded": "0",
              "epg_id": "5fd00ffdc8d1452ea066bd61",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00ffec8d1452ea066bd62",
              "title": "Ekaant",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "16:30:00",
              "duration": 30,
              "synopsis": "The host explores abandoned historical spots in India that are filled with mystery and studies how these once-flourishing places came to be in ruins.",
              "progdate": "2020-12-10",
              "image_public_id": "460ED4562E6E02F562F2DD1CA4694004640DBA76P",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549976895",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607598000,
              "end_timestamp": 1607599800,
              "isReminded": "0",
              "epg_id": "5fd00ffec8d1452ea066bd62",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd00fffc8d1452ea066bd63",
              "title": "Sanrachna",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "17:00:00",
              "duration": 30,
              "synopsis": "The team travels to the Ajanta Caves in Maharashtra and the Udayagiri and Khandagiri Caves in Orissa, to unravel the mystery behind their historical architecture.",
              "progdate": "2020-12-10",
              "image_public_id": "CE5AF3DF118092A96DB36175401260556215ABE4H",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549982646",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607599800,
              "end_timestamp": 1607601600,
              "isReminded": "0",
              "epg_id": "5fd00fffc8d1452ea066bd63",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd01000c8d1452ea066bd64",
              "title": "Kahi Suni",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "17:30:00",
              "duration": 30,
              "synopsis": "Sara explores the legend of Baz Bahadur and Rani Roopmati who committed suicide to avoid being captured by Akbar's general, Adham Khan, after he took over Mandu.",
              "progdate": "2020-12-10",
              "image_public_id": "C810C19B0642BFAA510E321C1AF3D3BF7BCD42E59",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549976894",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607601600,
              "end_timestamp": 1607603400,
              "isReminded": "0",
              "epg_id": "5fd01000c8d1452ea066bd64",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd01001c8d1452ea066bd65",
              "title": "Adrishya",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "18:00:00",
              "duration": 60,
              "synopsis": "The filmmaker explores the lives of India's greatest spies, the dangers they faced as they executed their missions and how they risked their lives for national honour.",
              "progdate": "2020-12-10",
              "image_public_id": "A4B4473EDC79B352E90536A329B5CCF656BA26B3B",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549975676",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607603400,
              "end_timestamp": 1607607000,
              "isReminded": "0",
              "epg_id": "5fd01001c8d1452ea066bd65",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd01003c8d1452ea066bd66",
              "title": "Raja, Rasoi Aur Anya Kahaniyaan",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "19:00:00",
              "duration": 30,
              "synopsis": "Food enthusiasts provide information about the local cuisines in several regions of India and the extravagant culinary traditions of Indian royalty.",
              "progdate": "2020-12-10",
              "image_public_id": "E3B25A5AE2F06C67B8791A9D74BAF5492F8274EB4",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549975664",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607607000,
              "end_timestamp": 1607608800,
              "isReminded": "0",
              "epg_id": "5fd01003c8d1452ea066bd66",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd01004c8d1452ea066bd67",
              "title": "Lost Recipes",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "19:30:00",
              "duration": 30,
              "synopsis": "The host journeys across various regions of India to revive and popularise forgotten traditional recipes.",
              "progdate": "2020-12-10",
              "image_public_id": "C9E8CD2B80A403E37F00C849A3C469BFAA2F8EAF4",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550000138",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607608800,
              "end_timestamp": 1607610600,
              "isReminded": "0",
              "epg_id": "5fd01004c8d1452ea066bd67",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd01005c8d1452ea066bd68",
              "title": "Raja, Rasoi Aur Anya Kahaniyaan",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "20:00:00",
              "duration": 60,
              "synopsis": "Food enthusiasts provide information about the local cuisines in several regions of India and the extravagant culinary traditions of Indian royalty.",
              "progdate": "2020-12-10",
              "image_public_id": "89EC80BEE3B2801696C9EEFDC63F6E572D7CDCF8L",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549975664",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607610600,
              "end_timestamp": 1607614200,
              "isReminded": "0",
              "epg_id": "5fd01005c8d1452ea066bd68",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd01007c8d1452ea066bd69",
              "title": "Tyohar Ki Thaali",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "21:00:00",
              "duration": 30,
              "synopsis": "The host shares recipes of dishes associated with various festivals that are celebrated in India. She also communicates tales of legends and myths from the rich Indian folklore.",
              "progdate": "2020-12-10",
              "image_public_id": "0D1266F052CE83B77FB5CD268FD7375630A57BADR",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550368466",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607614200,
              "end_timestamp": 1607616000,
              "isReminded": "0",
              "epg_id": "5fd01007c8d1452ea066bd69",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd01008c8d1452ea066bd6a",
              "title": "Wild Wild India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "21:30:00",
              "duration": 30,
              "synopsis": "The host explores the state of India's wildlife, the animal rights activists and conservationists who do their best to support it as well as the various threats these animals face.",
              "progdate": "2020-12-10",
              "image_public_id": "FBBDAA1096BA3CC402492032B5F14AFFE7F4CA00W",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000550662474",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607616000,
              "end_timestamp": 1607617800,
              "isReminded": "0",
              "epg_id": "5fd01008c8d1452ea066bd6a",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd01009c8d1452ea066bd6b",
              "title": "Rakkt",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "22:00:00",
              "duration": 30,
              "synopsis": "Major events, wars, crimes and assassinations that changed the course of India's history and shaped its current society are brought into focus.",
              "progdate": "2020-12-10",
              "image_public_id": "16FC9900F290642D88F0E5D221A3E781C45FB86D0",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549976896",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607617800,
              "end_timestamp": 1607619600,
              "isReminded": "0",
              "epg_id": "5fd01009c8d1452ea066bd6b",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100ac8d1452ea066bd6c",
              "title": "Rakkt",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "22:30:00",
              "duration": 30,
              "synopsis": "Major events, wars, crimes and assassinations that changed the course of India's history and shaped its current society are brought into focus.",
              "progdate": "2020-12-10",
              "image_public_id": "D73714C7EC05334952805ACFB99D3F1C2B931D51O",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549976896",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607619600,
              "end_timestamp": 1607621400,
              "isReminded": "0",
              "epg_id": "5fd0100ac8d1452ea066bd6c",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd6d",
              "title": "Epic Ke Dus",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "23:00:00",
              "duration": 60,
              "synopsis": "Experts share their views on ten thoroughly researched people, places or events that changed the course of history in India.",
              "progdate": "2020-12-10",
              "image_public_id": "B519897669A2F35837374FCED9F449831E67D23A0",
              "logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "id": "30000000549975673",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607621400,
              "end_timestamp": 1607625000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd6d",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd6e",
              "title": "Indipedia",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "00:00:00",
              "duration": 30,
              "synopsis": "The host travels to different states in India and learns more about the customs and traditions that have been a part of the country since ancient times.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550375314",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607625000,
              "end_timestamp": 1607626800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd6e",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd6f",
              "title": "Sanrachna",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "00:30:00",
              "duration": 30,
              "synopsis": "The Lepakshi temple in Andhra Pradesh and the Vitthala temple of Hampi in Karnataka are two monuments which stand strong today as the perfect examples of imperial architecture.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549982646",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607626800,
              "end_timestamp": 1607628600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd6f",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd70",
              "title": "Wild Wild India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "01:00:00",
              "duration": 30,
              "synopsis": "The host explores the state of India's wildlife, the animal rights activists and conservationists who do their best to support it as well as the various threats these animals face.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550662474",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607628600,
              "end_timestamp": 1607630400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd70",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd71",
              "title": "Epic Ke Dus",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "01:30:00",
              "duration": 60,
              "synopsis": "Experts share their views on ten thoroughly researched people, places or events that changed the course of history in India.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975673",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607630400,
              "end_timestamp": 1607634000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd71",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd72",
              "title": "Ekaant",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "02:30:00",
              "duration": 30,
              "synopsis": "The host explores abandoned historical spots in India that are filled with mystery and studies how these once-flourishing places came to be in ruins.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976895",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607634000,
              "end_timestamp": 1607635800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd72",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd73",
              "title": "Kahi Suni",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "03:00:00",
              "duration": 30,
              "synopsis": "Sara explores the story of Hidimbi from the Mahabharata, who marries Bhima after he kills her brother and gives birth to his son Ghatotkacha.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976894",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607635800,
              "end_timestamp": 1607637600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd73",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd74",
              "title": "Sanrachna",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "03:30:00",
              "duration": 30,
              "synopsis": "The team travels to the Ajanta Caves in Maharashtra and the Udayagiri and Khandagiri Caves in Orissa, to unravel the mystery behind their historical architecture.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549982646",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607637600,
              "end_timestamp": 1607639400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd74",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd75",
              "title": "Adrishya",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "04:00:00",
              "duration": 60,
              "synopsis": "Amar Bhushan, once the number two man of RAW, led a surveillance team to monitor an agency suspect, risking his job for national security.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975676",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607639400,
              "end_timestamp": 1607643000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd75",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd76",
              "title": "Sharanam",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "05:00:00",
              "duration": 30,
              "synopsis": "The host speaks about religiously important locations across the country and presents a compilation of videos in which pilgrims share their experiences of visiting different holy sites.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550407318",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607643000,
              "end_timestamp": 1607644800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd76",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd77",
              "title": "Buddha Sutra",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "05:30:00",
              "duration": 30,
              "synopsis": "The narrator presents an interesting account of Buddha's journey, his teachings and its widespread impact on art, architecture and politics.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550699244",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607644800,
              "end_timestamp": 1607646600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd77",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd78",
              "title": "Epicgrams",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "06:00:00",
              "duration": 30,
              "synopsis": "The host presents fascinating facets of Indian history related to personalities in various fields in a unique manner that speaks to younger audiences.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550050270",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607646600,
              "end_timestamp": 1607648400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd78",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd79",
              "title": "Made In India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "06:30:00",
              "duration": 30,
              "synopsis": "Experts discuss the history of the remarkable inventions that originated in India and explain the significance of the country's contributions to the world.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976897",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607648400,
              "end_timestamp": 1607650200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd79",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd7a",
              "title": "Sharanam",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "07:00:00",
              "duration": 30,
              "synopsis": "The host speaks about religiously important locations across the country and presents a compilation of videos in which pilgrims share their experiences of visiting different holy sites.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550407318",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607650200,
              "end_timestamp": 1607652000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd7a",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd7b",
              "title": "Indipedia",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "07:30:00",
              "duration": 30,
              "synopsis": "The host travels to different states in India and learns more about the customs and traditions that have been a part of the country since ancient times.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550375314",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607652000,
              "end_timestamp": 1607653800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd7b",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd7c",
              "title": "Sharanam",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "08:00:00",
              "duration": 30,
              "synopsis": "The host speaks about religiously important locations across the country and presents a compilation of videos in which pilgrims share their experiences of visiting different holy sites.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550407318",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607653800,
              "end_timestamp": 1607655600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd7c",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd7d",
              "title": "Ekaant",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "08:30:00",
              "duration": 30,
              "synopsis": "The host explores abandoned historical spots in India that are filled with mystery and studies how these once-flourishing places came to be in ruins.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976895",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607655600,
              "end_timestamp": 1607657400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd7d",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd7e",
              "title": "Raja, Rasoi Aur Anya Kahaniyaan",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "09:00:00",
              "duration": 60,
              "synopsis": "Food enthusiasts provide information about the local cuisines in several regions of India and the extravagant culinary traditions of Indian royalty.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975664",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607657400,
              "end_timestamp": 1607661000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd7e",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd7f",
              "title": "Epic Special",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "10:00:00",
              "duration": 60,
              "synopsis": "Various motorbike enthusiasts talk about their experience of riding a particular brand's bikes and shed light on its various features and history.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550522513",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607661000,
              "end_timestamp": 1607664600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd7f",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd80",
              "title": "Kahi Suni",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "11:00:00",
              "duration": 30,
              "synopsis": "Sara explores the legend of Baz Bahadur and Rani Roopmati who committed suicide to avoid being captured by Akbar's general, Adham Khan, after he took over Mandu.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976894",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607664600,
              "end_timestamp": 1607666400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd80",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd81",
              "title": "Ekaant",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "11:30:00",
              "duration": 30,
              "synopsis": "The host explores abandoned historical spots in India that are filled with mystery and studies how these once-flourishing places came to be in ruins.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976895",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607666400,
              "end_timestamp": 1607668200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd81",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd82",
              "title": "Raja, Rasoi Aur Anya Kahaniyaan",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "12:00:00",
              "duration": 30,
              "synopsis": "Food enthusiasts provide information about the local cuisines in several regions of India and the extravagant culinary traditions of Indian royalty.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975664",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607668200,
              "end_timestamp": 1607670000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd82",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd83",
              "title": "Safarnama",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "12:30:00",
              "duration": 30,
              "synopsis": "Ankit Siwach visits some of the unexplored destinations in India and provides an insight into their culture, heritage, cuisine and natural beauty.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550757067",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607670000,
              "end_timestamp": 1607671800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd83",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd84",
              "title": "Raja, Rasoi Aur Anya Kahaniyaan",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "13:00:00",
              "duration": 60,
              "synopsis": "Food enthusiasts provide information about the local cuisines in several regions of India and the extravagant culinary traditions of Indian royalty.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975664",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607671800,
              "end_timestamp": 1607675400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd84",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd85",
              "title": "Lost Recipes",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "14:00:00",
              "duration": 60,
              "synopsis": "The host journeys across various regions of India to revive and popularise forgotten traditional recipes.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550000138",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607675400,
              "end_timestamp": 1607679000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd85",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd86",
              "title": "Raja, Rasoi Aur Andaaz Anokha",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "15:00:00",
              "duration": 30,
              "synopsis": "Ranveer Brar, an Indian chef, shares the recipes of a variety of mouthwatering dishes and demonstrates the steps involved in their preparation.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550352928",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607679000,
              "end_timestamp": 1607680800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd86",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd87",
              "title": "Tyohar Ki Thaali",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "15:30:00",
              "duration": 30,
              "synopsis": "The host shares recipes of dishes associated with various festivals that are celebrated in India. She also communicates tales of legends and myths from the rich Indian folklore.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550368466",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607680800,
              "end_timestamp": 1607682600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd87",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd88",
              "title": "Wild Wild India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "16:00:00",
              "duration": 30,
              "synopsis": "The host explores the state of India's wildlife, the animal rights activists and conservationists who do their best to support it as well as the various threats these animals face.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550662474",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607682600,
              "end_timestamp": 1607684400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd88",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd89",
              "title": "Ekaant",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "16:30:00",
              "duration": 30,
              "synopsis": "The host explores abandoned historical spots in India that are filled with mystery and studies how these once-flourishing places came to be in ruins.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976895",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607684400,
              "end_timestamp": 1607686200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd89",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd8a",
              "title": "Sanrachna",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "17:00:00",
              "duration": 30,
              "synopsis": "The self-replicating repetition of a pattern is the characteristic feature of temples at Khajuraho and their genius could only be explained by the mathematicians of the 20th century.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549982646",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607686200,
              "end_timestamp": 1607688000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd8a",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd8b",
              "title": "Kahi Suni",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "17:30:00",
              "duration": 30,
              "synopsis": "Sara explores the legend of Baz Bahadur and Rani Roopmati who committed suicide to avoid being captured by Akbar's general, Adham Khan, after he took over Mandu.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976894",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607688000,
              "end_timestamp": 1607689800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd8b",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd8c",
              "title": "Adrishya",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "18:00:00",
              "duration": 60,
              "synopsis": "The filmmaker explores the lives of India's greatest spies, the dangers they faced as they executed their missions and how they risked their lives for national honour.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975676",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607689800,
              "end_timestamp": 1607693400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd8c",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd8d",
              "title": "Raja, Rasoi Aur Anya Kahaniyaan",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "19:00:00",
              "duration": 30,
              "synopsis": "Food enthusiasts provide information about the local cuisines in several regions of India and the extravagant culinary traditions of Indian royalty.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975664",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607693400,
              "end_timestamp": 1607695200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd8d",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd8e",
              "title": "Lost Recipes",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "19:30:00",
              "duration": 30,
              "synopsis": "The host journeys across various regions of India to revive and popularise forgotten traditional recipes.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550000138",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607695200,
              "end_timestamp": 1607697000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd8e",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd8f",
              "title": "Raja, Rasoi Aur Anya Kahaniyaan",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "20:00:00",
              "duration": 60,
              "synopsis": "Food enthusiasts provide information about the local cuisines in several regions of India and the extravagant culinary traditions of Indian royalty.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975664",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607697000,
              "end_timestamp": 1607700600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd8f",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd90",
              "title": "Tyohar Ki Thaali",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "21:00:00",
              "duration": 30,
              "synopsis": "The host shares recipes of dishes associated with various festivals that are celebrated in India. She also communicates tales of legends and myths from the rich Indian folklore.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550368466",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607700600,
              "end_timestamp": 1607702400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd90",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd91",
              "title": "Wild Wild India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "21:30:00",
              "duration": 30,
              "synopsis": "The host explores the state of India's wildlife, the animal rights activists and conservationists who do their best to support it as well as the various threats these animals face.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550662474",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607702400,
              "end_timestamp": 1607704200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd91",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd92",
              "title": "Rakkt",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "22:00:00",
              "duration": 30,
              "synopsis": "Major events, wars, crimes and assassinations that changed the course of India's history and shaped its current society are brought into focus.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976896",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607704200,
              "end_timestamp": 1607706000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd92",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd93",
              "title": "Rakkt",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "22:30:00",
              "duration": 30,
              "synopsis": "Major events, wars, crimes and assassinations that changed the course of India's history and shaped its current society are brought into focus.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976896",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607706000,
              "end_timestamp": 1607707800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd93",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd94",
              "title": "Epic Ke Dus",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "23:00:00",
              "duration": 60,
              "synopsis": "Experts share their views on ten thoroughly researched people, places or events that changed the course of history in India.",
              "progdate": "2020-12-11",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975673",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607707800,
              "end_timestamp": 1607711400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd94",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd95",
              "title": "Indipedia",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "00:00:00",
              "duration": 30,
              "synopsis": "The host travels to different states in India and learns more about the customs and traditions that have been a part of the country since ancient times.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550375314",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607711400,
              "end_timestamp": 1607713200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd95",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd96",
              "title": "Sanrachna",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "00:30:00",
              "duration": 30,
              "synopsis": "In an attempt to connect the existing world to the ancient times, the team visits the doorways of India, the Buland Darwaza in Fatehpur Sikri in Agra, and Charminar in Hyderabad.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549982646",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607713200,
              "end_timestamp": 1607715000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd96",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd97",
              "title": "Wild Wild India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "01:00:00",
              "duration": 30,
              "synopsis": "The host explores the state of India's wildlife, the animal rights activists and conservationists who do their best to support it as well as the various threats these animals face.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550662474",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607715000,
              "end_timestamp": 1607716800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd97",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd98",
              "title": "Epic Ke Dus",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "01:30:00",
              "duration": 60,
              "synopsis": "Experts share their views on ten thoroughly researched people, places or events that changed the course of history in India.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975673",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607716800,
              "end_timestamp": 1607720400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd98",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd99",
              "title": "Ekaant",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "02:30:00",
              "duration": 30,
              "synopsis": "The host explores abandoned historical spots in India that are filled with mystery and studies how these once-flourishing places came to be in ruins.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976895",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607720400,
              "end_timestamp": 1607722200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd99",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd9a",
              "title": "Kahi Suni",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "03:00:00",
              "duration": 30,
              "synopsis": "Sara travels to Rudraprayag and explores the story of Parvati and Shiva's marriage, which was supposed to have taken place in Trijuginarayan Temple.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976894",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607722200,
              "end_timestamp": 1607724000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd9a",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd9b",
              "title": "Sanrachna",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "03:30:00",
              "duration": 30,
              "synopsis": "The self-replicating repetition of a pattern is the characteristic feature of temples at Khajuraho and their genius could only be explained by the mathematicians of the 20th century.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549982646",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607724000,
              "end_timestamp": 1607725800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd9b",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd9c",
              "title": "Adrishya",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "04:00:00",
              "duration": 60,
              "synopsis": "Jeevsiddhi, who is trained by Chanakya, enters the innermost circles of Rakshasa's court attempting to turn an enemy of the state into one of its most powerful ministers.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975676",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607725800,
              "end_timestamp": 1607729400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd9c",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd9d",
              "title": "Sharanam",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "05:00:00",
              "duration": 30,
              "synopsis": "The host speaks about religiously important locations across the country and presents a compilation of videos in which pilgrims share their experiences of visiting different holy sites.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550407318",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607729400,
              "end_timestamp": 1607731200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd9d",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd9e",
              "title": "Buddha Sutra",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "05:30:00",
              "duration": 30,
              "synopsis": "The narrator presents an interesting account of Buddha's journey, his teachings and its widespread impact on art, architecture and politics.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550699244",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607731200,
              "end_timestamp": 1607733000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd9e",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bd9f",
              "title": "Epicgrams",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "06:00:00",
              "duration": 30,
              "synopsis": "The host presents fascinating facets of Indian history related to personalities in various fields in a unique manner that speaks to younger audiences.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550050270",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607733000,
              "end_timestamp": 1607734800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bd9f",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bda0",
              "title": "Made In India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "06:30:00",
              "duration": 30,
              "synopsis": "Experts discuss the history of the remarkable inventions that originated in India and explain the significance of the country's contributions to the world.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976897",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607734800,
              "end_timestamp": 1607736600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bda0",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bda1",
              "title": "Sharanam",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "07:00:00",
              "duration": 30,
              "synopsis": "The host speaks about religiously important locations across the country and presents a compilation of videos in which pilgrims share their experiences of visiting different holy sites.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550407318",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607736600,
              "end_timestamp": 1607738400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bda1",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bda2",
              "title": "Indipedia",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "07:30:00",
              "duration": 30,
              "synopsis": "The host travels to different states in India and learns more about the customs and traditions that have been a part of the country since ancient times.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550375314",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607738400,
              "end_timestamp": 1607740200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bda2",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bda3",
              "title": "Dharmakshetra",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "08:00:00",
              "duration": 60,
              "synopsis": "After the end of the war at Kurukshetra, the Kauravas and Pandavas are judged on the basis of their karma. Accordingly, the warriors either go to heaven or hell.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975663",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607740200,
              "end_timestamp": 1607743800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bda3",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bda4",
              "title": "Epic IQ Challenge",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "09:00:00",
              "duration": 60,
              "synopsis": "School students from all over the country come together to compete in a quiz that celebrates India as it is based on a wide range of topics including culture, history and current affairs of India.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550652266",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607743800,
              "end_timestamp": 1607747400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bda4",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bda5",
              "title": "Parikrama",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "10:00:00",
              "duration": 30,
              "synopsis": "A show focussing on religion or spirituality.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550765744",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607747400,
              "end_timestamp": 1607749200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bda5",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bda6",
              "title": "Parikrama",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "10:30:00",
              "duration": 30,
              "synopsis": "A show focussing on religion or spirituality.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550765744",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607749200,
              "end_timestamp": 1607751000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bda6",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bda7",
              "title": "Kahi Suni",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "11:00:00",
              "duration": 30,
              "synopsis": "In Ujjain, Sara explores the story of the conflict between Lord Brahma and Lord Vishnu over who is the more superior God.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976894",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607751000,
              "end_timestamp": 1607752800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bda7",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bda8",
              "title": "Tyohar Ki Thaali",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "11:30:00",
              "duration": 30,
              "synopsis": "The host shares recipes of dishes associated with various festivals that are celebrated in India. She also communicates tales of legends and myths from the rich Indian folklore.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550368466",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607752800,
              "end_timestamp": 1607754600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bda8",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bda9",
              "title": "Eco India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "12:00:00",
              "duration": 30,
              "synopsis": "The host showcases the efforts of an organisation that aims to promote the use of sustainable energy resources and undertakes initiatives to launch eco-friendly day-to-day products.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550787988",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607754600,
              "end_timestamp": 1607756400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bda9",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdaa",
              "title": "Eco India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "12:30:00",
              "duration": 30,
              "synopsis": "The host showcases the efforts of an organisation that aims to promote the use of sustainable energy resources and undertakes initiatives to launch eco-friendly day-to-day products.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550787988",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607756400,
              "end_timestamp": 1607758200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdaa",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdab",
              "title": "Safarnama",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "13:00:00",
              "duration": 30,
              "synopsis": "Ankit Siwach visits some of the unexplored destinations in India and provides an insight into their culture, heritage, cuisine and natural beauty.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550757067",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607758200,
              "end_timestamp": 1607760000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdab",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdac",
              "title": "Ekaant",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "13:30:00",
              "duration": 30,
              "synopsis": "The host explores abandoned historical spots in India that are filled with mystery and studies how these once-flourishing places came to be in ruins.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976895",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607760000,
              "end_timestamp": 1607761800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdac",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdad",
              "title": "Raja, Rasoi Aur Andaaz Anokha",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "14:00:00",
              "duration": 30,
              "synopsis": "Ranveer Brar, an Indian chef, shares the recipes of a variety of mouthwatering dishes and demonstrates the steps involved in their preparation.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550352928",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607761800,
              "end_timestamp": 1607763600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdad",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdae",
              "title": "Raja, Rasoi Aur Andaaz Anokha",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "14:30:00",
              "duration": 30,
              "synopsis": "Ranveer Brar, an Indian chef, shares the recipes of a variety of mouthwatering dishes and demonstrates the steps involved in their preparation.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550352928",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607763600,
              "end_timestamp": 1607765400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdae",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdaf",
              "title": "Sanrachna",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "15:00:00",
              "duration": 30,
              "synopsis": "Host Vaibhavi travels across India visiting the famous historical and architectural monuments, discovering the secrets behind the existence and structure of these engineering marvels.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549982646",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607765400,
              "end_timestamp": 1607767200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdaf",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdb0",
              "title": "Kahi Suni",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "15:30:00",
              "duration": 30,
              "synopsis": "Host Sara Khan travels the length and breadth of India and explores the established myths and legends. She tries to decode them and discovers their origin and the reasons they are prevalent.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976894",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607767200,
              "end_timestamp": 1607769000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdb0",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdb1",
              "title": "Lost Recipes",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "16:00:00",
              "duration": 60,
              "synopsis": "The host journeys across various regions of India to revive and popularise forgotten traditional recipes.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550000138",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607769000,
              "end_timestamp": 1607772600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdb1",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdb2",
              "title": "Ekaant",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "17:00:00",
              "duration": 30,
              "synopsis": "The host explores abandoned historical spots in India that are filled with mystery and studies how these once-flourishing places came to be in ruins.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976895",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607772600,
              "end_timestamp": 1607774400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdb2",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdb3",
              "title": "Safarnama",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "17:30:00",
              "duration": 30,
              "synopsis": "Ankit Siwach visits some of the unexplored destinations in India and provides an insight into their culture, heritage, cuisine and natural beauty.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550757067",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607774400,
              "end_timestamp": 1607776200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdb3",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdb4",
              "title": "Parikrama",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "18:00:00",
              "duration": 30,
              "synopsis": "A show focussing on religion or spirituality.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550765744",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607776200,
              "end_timestamp": 1607778000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdb4",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdb5",
              "title": "Parikrama",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "18:30:00",
              "duration": 30,
              "synopsis": "A show focussing on religion or spirituality.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550765744",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607778000,
              "end_timestamp": 1607779800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdb5",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdb6",
              "title": "Raja, Rasoi Aur Andaaz Anokha",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "19:00:00",
              "duration": 30,
              "synopsis": "Ranveer Brar, an Indian chef, shares the recipes of a variety of mouthwatering dishes and demonstrates the steps involved in their preparation.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550352928",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607779800,
              "end_timestamp": 1607781600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdb6",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdb7",
              "title": "Raja, Rasoi Aur Andaaz Anokha",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "19:30:00",
              "duration": 30,
              "synopsis": "Ranveer Brar, an Indian chef, shares the recipes of a variety of mouthwatering dishes and demonstrates the steps involved in their preparation.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550352928",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607781600,
              "end_timestamp": 1607783400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdb7",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdb8",
              "title": "Eco India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "20:00:00",
              "duration": 30,
              "synopsis": "The host showcases the efforts of an organisation that aims to promote the use of sustainable energy resources and undertakes initiatives to launch eco-friendly day-to-day products.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550787988",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607783400,
              "end_timestamp": 1607785200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdb8",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdb9",
              "title": "Eco India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "20:30:00",
              "duration": 30,
              "synopsis": "The host showcases the efforts of an organisation that aims to promote the use of sustainable energy resources and undertakes initiatives to launch eco-friendly day-to-day products.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550787988",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607785200,
              "end_timestamp": 1607787000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdb9",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdba",
              "title": "Siyaasat",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "21:00:00",
              "duration": 60,
              "synopsis": "Salim sends Nisa a beautiful bracelet and later meets her at Meena Bazaar to sneak her away. Their romance begins to become the talk of the town.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975672",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607787000,
              "end_timestamp": 1607790600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdba",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdbb",
              "title": "Adrishya",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "22:00:00",
              "duration": 60,
              "synopsis": "The filmmaker explores the lives of India's greatest spies, the dangers they faced as they executed their missions and how they risked their lives for national honour.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975676",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607790600,
              "end_timestamp": 1607794200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdbb",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdbc",
              "title": "Epic Ke Dus",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "23:00:00",
              "duration": 60,
              "synopsis": "Experts share their views on ten thoroughly researched people, places or events that changed the course of history in India.",
              "progdate": "2020-12-12",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975673",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607794200,
              "end_timestamp": 1607797800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdbc",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdbd",
              "title": "Indipedia",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "00:00:00",
              "duration": 30,
              "synopsis": "The host travels to different states in India and learns more about the customs and traditions that have been a part of the country since ancient times.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550375314",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607797800,
              "end_timestamp": 1607799600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdbd",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdbe",
              "title": "Sanrachna",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "00:30:00",
              "duration": 30,
              "synopsis": "Host Vaibhavi travels across India visiting the famous historical and architectural monuments, discovering the secrets behind the existence and structure of these engineering marvels.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549982646",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607799600,
              "end_timestamp": 1607801400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdbe",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdbf",
              "title": "Wild Wild India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "01:00:00",
              "duration": 30,
              "synopsis": "The host explores the state of India's wildlife, the animal rights activists and conservationists who do their best to support it as well as the various threats these animals face.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550662474",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607801400,
              "end_timestamp": 1607803200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdbf",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdc0",
              "title": "Epic Ke Dus",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "01:30:00",
              "duration": 60,
              "synopsis": "Experts share their views on ten thoroughly researched people, places or events that changed the course of history in India.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975673",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607803200,
              "end_timestamp": 1607806800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdc0",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdc1",
              "title": "Ekaant",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "02:30:00",
              "duration": 30,
              "synopsis": "The host explores abandoned historical spots in India that are filled with mystery and studies how these once-flourishing places came to be in ruins.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976895",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607806800,
              "end_timestamp": 1607808600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdc1",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdc2",
              "title": "Kahi Suni",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "03:00:00",
              "duration": 30,
              "synopsis": "Sara explores the story of Shaktipeeth and Parvati's scattered body parts, when she burns herself in sati.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976894",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607808600,
              "end_timestamp": 1607810400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdc2",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdc3",
              "title": "Sanrachna",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "03:30:00",
              "duration": 30,
              "synopsis": "The team travels to Mandu in Madhya Pradesh and the Kanheri Caves in Maharashtra. The establishments stand strong as the founding examples of correct water usage and recycling.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549982646",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607810400,
              "end_timestamp": 1607812200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdc3",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdc4",
              "title": "Adrishya",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "04:00:00",
              "duration": 60,
              "synopsis": "At the head of Shivaji's army was Bahirji Naik, the chief intelligence officer who helped bring down the Adil Shahi Empire with just 300 odd men.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975676",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607812200,
              "end_timestamp": 1607815800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdc4",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdc5",
              "title": "Sharanam",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "05:00:00",
              "duration": 30,
              "synopsis": "The host speaks about religiously important locations across the country and presents a compilation of videos in which pilgrims share their experiences of visiting different holy sites.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550407318",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607815800,
              "end_timestamp": 1607817600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdc5",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdc6",
              "title": "Buddha Sutra",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "05:30:00",
              "duration": 30,
              "synopsis": "The narrator presents an interesting account of Buddha's journey, his teachings and its widespread impact on art, architecture and politics.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550699244",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607817600,
              "end_timestamp": 1607819400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdc6",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdc7",
              "title": "Epicgrams",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "06:00:00",
              "duration": 30,
              "synopsis": "The host presents fascinating facets of Indian history related to personalities in various fields in a unique manner that speaks to younger audiences.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550050270",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607819400,
              "end_timestamp": 1607821200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdc7",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdc8",
              "title": "Made In India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "06:30:00",
              "duration": 30,
              "synopsis": "Experts discuss the history of the remarkable inventions that originated in India and explain the significance of the country's contributions to the world.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976897",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607821200,
              "end_timestamp": 1607823000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdc8",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdc9",
              "title": "Sharanam",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "07:00:00",
              "duration": 30,
              "synopsis": "The host speaks about religiously important locations across the country and presents a compilation of videos in which pilgrims share their experiences of visiting different holy sites.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550407318",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607823000,
              "end_timestamp": 1607824800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdc9",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdca",
              "title": "Indipedia",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "07:30:00",
              "duration": 30,
              "synopsis": "The host travels to different states in India and learns more about the customs and traditions that have been a part of the country since ancient times.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550375314",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607824800,
              "end_timestamp": 1607826600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdca",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdcb",
              "title": "Dharmakshetra",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "08:00:00",
              "duration": 60,
              "synopsis": "After the end of the war at Kurukshetra, the Kauravas and Pandavas are judged on the basis of their karma. Accordingly, the warriors either go to heaven or hell.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549975663",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607826600,
              "end_timestamp": 1607830200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdcb",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdcc",
              "title": "Epic IQ Challenge",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "09:00:00",
              "duration": 60,
              "synopsis": "School students from all over the country come together to compete in a quiz that celebrates India as it is based on a wide range of topics including culture, history and current affairs of India.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550652266",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607830200,
              "end_timestamp": 1607833800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdcc",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdcd",
              "title": "Parikrama",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "10:00:00",
              "duration": 30,
              "synopsis": "A show focussing on religion or spirituality.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550765744",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607833800,
              "end_timestamp": 1607835600,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdcd",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdce",
              "title": "Parikrama",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "10:30:00",
              "duration": 30,
              "synopsis": "A show focussing on religion or spirituality.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550765744",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607835600,
              "end_timestamp": 1607837400,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdce",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdcf",
              "title": "Kahi Suni",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "11:00:00",
              "duration": 30,
              "synopsis": "In Dhosi Hill, Sara explores the magical transformation of the old Chyavana rishi into a young man and the formation of modern-day Ayurveda principles.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000549976894",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607837400,
              "end_timestamp": 1607839200,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdcf",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdd0",
              "title": "Tyohar Ki Thaali",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "11:30:00",
              "duration": 30,
              "synopsis": "The host shares recipes of dishes associated with various festivals that are celebrated in India. She also communicates tales of legends and myths from the rich Indian folklore.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550368466",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607839200,
              "end_timestamp": 1607841000,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdd0",
              "code": "LIV410",
              "type": "livetv"
          },
          {
              "_id": "5fd0100cc8d1452ea066bdd1",
              "title": "Eco India",
              "channel_logo": "https://imagesdigivive.whatsonindia.com/dasimages/channel/landscape/50x38/cqwwVotk.png",
              "progtime": "12:00:00",
              "duration": 30,
              "synopsis": "The host showcases the efforts of an organisation that aims to promote the use of sustainable energy resources and undertakes initiatives to launch eco-friendly day-to-day products.",
              "progdate": "2020-12-13",
              "image_public_id": "1539673820",
              "logo": "https://res.cloudinary.com/digivive/image/upload/v1539673855/1539673820.jpg",
              "id": "30000000550787988",
              "channel": "EPIC",
              "epgid": "1027",
              "start_timestamp": 1607841000,
              "end_timestamp": 1607842800,
              "isReminded": "0",
              "epg_id": "5fd0100cc8d1452ea066bdd1",
              "code": "LIV410",
              "type": "livetv"
          }
      ]
   }

    public static readonly epgReqParms = {
        "no_of_days":8,
        "plateform":"mobile_app",
        "catlogue":CatlougeTypeConstants.catlogue,
        "from_date":"2020-12-17",
        "channel_code":"CHN315",
        "epg_id":"795",
        "code":"LIV410"
        }
    }
