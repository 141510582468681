import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core';
import { CatlougeTypeConstants } from 'src/app/core/constants';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { AccountService } from '../../account/account.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { environment } from 'src/environments/environment';
declare var $: any;
declare var Razorpay: any;
@Component({
  selector: 'app-chose-plan',
  templateUrl: './chose-plan.component.html',
  styleUrls: ['./chose-plan.component.css']
})
export class ChosePlanComponent implements OnInit {
  radioStatus: boolean;
  selectedRentPayment: boolean;
  groupPack: any =[];
  fristPack: any;
  grouplength: any;
  noPackFound: any;
  details_error: string = '';
  packs: any = [];
  rentedPacks: any = [];
  selectedChargeCode = '';
  selectedRentChargeCode = '';
  chargeCode: string = '';
  paymentArray: any = {};
  payButtonText: string = 'PAY NOW';
  searchSubscriber: any;
  userInfo: any;
  userUnique: string = 'NA';
  selected_payment_gateway: string = '';
  continuePressed: boolean = false;
  rentContinuePressed: boolean = false;
  addPromoForm: FormGroup;
  promocode_error: string = '';
  catlogue = CatlougeTypeConstants.catlogue;
  @ViewChild('add_promocodesuccess') add_promocodesuccess;
  @ViewChild('closePromoButton') closePromoButton;
  @ViewChild('loginPopup') loginPopup;
  selectedIndex: number = 0;

  userId: string;
  username: string;
  isUsernameMobile: boolean;
  userMobile: string;
  userEmail: string;
  isCouponApplied: boolean = false;
  isRazorpayPayment: boolean = false;
  razorpayPaymentMethods: any;
  paymentModes: any[] = [];
  selectedPaymentGateway: any;
  selectedPaymentMode: any;
  queryParamGroup: string;
  queryParamPack: string;
  queryParamGateway: string;
  selectedCardType: string;
  readonly encodingKey: string = '362c11585dadb201908fb18d861abb0d';
  readonly razorpayKey: string = environment.razorpay_api_key;
  cardForm: FormGroup;
  isCardFormSubmitted: boolean;
  upiType: string;
  upiVpa: string;
  upiError: string;
  netBankingForm: FormGroup;
  netBankingArray: any[] = [];
  walletsArray: any[] = [];
  selectedWallet: any;
  @ViewChild('closeApplyCoupon') closeApplyCoupon: ElementRef;
  isTransactionProcessing: boolean;
  isTransactionSuccess: boolean;
  isTransactionFailed: boolean;
  transactionSuccessObj: any;

  selectedGroup: any;
  selectedPack: any;
  isPackSelected: boolean = false;
  allCoupons: any[] = [];
  currentCouponsArray: any[] = [];
  appliedCoupon: any;
  couponInputValue: string = "";
  couponError: string;
  isApplyCouponSuccess: boolean;
  isPackRenewable: boolean = false;
  isRenewChecked: boolean = false;
  @ViewChild('paymentGatewayRadio') paymentGatewayRadio: ElementRef;
  
  constructor(
    private apiService: AccountService,
    private userService: UserService,
    private route: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    private googleEvent: EventTrackingService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private accountService: AccountService,
    private subscriptionService: SubscriptionService,
  ) {

      $("body").css("overflow-y", "scroll");
    this.addPromoForm = this.formBuilder.group({
      'promocode': ['']
    });
  }

  ngOnInit() {
    this.accountService
      .lazyLoadLibrary("https://checkout.razorpay.com/v1/razorpay.js")
      .subscribe(() => {
        this.getRazorpayPaymentModes();
      });
    document.body.scrollTop = 0;
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))?.result;


    if (this.userInfo && Object.keys(this.userInfo).length > 0) {
      if (this.userInfo.register_by == 'mobile') {
        this.userUnique = this.userInfo.mobile;
      } else {
        this.userUnique = this.userInfo.emailaddress;
      }
      this.userId = this.userInfo._id;
      this.username = this.userInfo.username;
      this.isUsernameMobile = this.userInfo.register_by === 'mobile';
      this.userEmail = this.userInfo.emailaddress;
      this.userMobile = this.userInfo.mobile;
    }
    $('#album_similar').modal('hide');
    $('#AudioFullButton i').removeClass('fa-angle-down');
    $('#AudioFullButton i').addClass('fa-angle-up');


    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))?.result;
    

    document.addEventListener('click', function () {

      var applyCouponElement = $("#applyCoupon.modal");
      setTimeout(() => {
        applyCouponElement.attr('aria-hidden', 'false')
      },2000);
    });

    this.route.queryParams.subscribe(params => {
      this.queryParamGroup = params['selectedGroup'];
      this.queryParamPack = params['selectedPack'];
      this.queryParamGateway = params['selectedGateway'];
    });

    // this.getPacksByGroup();
    this.getPaymentModes();
    this.initCardForm();
    this.initNetBankingForm();

  }
  ngAfterContentInit() {
    this.chargeCode = '';

    if (localStorage.getItem('isAuthenticated') == undefined || localStorage.getItem('isAuthenticated') == 'false') {
      // this.router.navigate(['/']);
      return;
    } else {
      this.getPlanDetail();
    }

  }
  ngAfterViewInit() {
    if (!localStorage.getItem('isAuthenticated')) {
      try {
        this.modalService.open(this.loginPopup, { ariaLabelledBy: 'modal-basic-title', windowClass: 'no_close', backdrop: 'static', keyboard: false }).result.then((result) => {
        }, (reason) => { });
      } catch (e) {
        console.log(e);
      }
    }
  }

  initCardForm() {
    this.cardForm = this.fb.group({
      number: ['', [Validators.required, Validators.minLength(19), Validators.maxLength(19)]],
      expiry: ['', [Validators.required, Validators.pattern('^(0[1-9]|1[0-2])\/(2[4-9]|[3-9][0-9])$')]], // this regex works for year 2024 and above
      cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
      name: ['', [Validators.required]],
    });
  }

  initNetBankingForm() {
    this.netBankingForm = this.fb.group({
      bank: [null, [Validators.required]],
    });
  }

  getPlanDetail() {
    this.searchSubscriber = this.route.queryParams.subscribe(params => {
      this.chargeCode = params['code'] == undefined ? '' : params['code'];
    });

    let requestData = { catlogue: CatlougeTypeConstants.catlogue, code: this.chargeCode, platform: 'web' };
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(requestData);
    //this.apiService.postData('getpackv2', urlEncodedBodyData).subscribe((data: any) => {
    this.apiService.postData('getpacksbygroup', urlEncodedBodyData).subscribe((data: any) => {
      // console.log(data.groupPack.record);
      
      if (data.error_code == '200') {
        this.noPackFound = data.error_string;
        this.groupPack = data.result.groupPack;
        this
        this.grouplength = this.groupPack.length;
        console.log('this.groupPack',this.groupPack);
        // this.fristPack = this.groupPack[0].packs[0];
        // console.log('this.fristPack', this.fristPack);
        
        this.selectedGroup = this.groupPack[0];
        this.selectedPack = this.selectedGroup.packs[0];
        this.selectedRentChargeCode = this.selectedPack.PM_CHARGECODE;
        // this.getCouponList();
        

      } else {
        this.details_error = data.error_string;
      }

    },
      error => {
        this.details_error = 'Something went wrong!! Please try again after some time.';
      },
      () => {
      }
    );
  }

  strToLower(str: string) {
    if (str)
      return str.toLowerCase().trim();
    else
      return str;
  }
  trimString(str: string) {
    if (str)
      return str.trim();
    else
      return str;
  }

  planName(planname: string) {
    let pack_name = '';
    switch (planname) {

      case "weekly_pack":
        pack_name = 'WEEKLY PLAN';
        break;
      case "monthly_pack":
        pack_name = 'MONTHLY PLAN';
        break;
      case "yearly_pack":
        pack_name = 'YEARLY PLAN';
        break;
      default:
        pack_name = planname;
        break;
    }

    return pack_name;

  }
  continueButtons(value, price: any, index) {
    this.paymentArray = {};
    $(".selectedRentPayment").prop("checked", false);
    // $("input[name=selectedRentPayment]").removeAttr('checked');
    $('.make_payment').addClass('active');
    // this.onRentItemChange(value, price, index);
    this.rentContinuePressed = true;
    if (this.selectedPack.PAYMENT_MODES?.length === 1) {
      setTimeout(() => {
        this.paymentGatewayRadio?.nativeElement.click();
      }, 10);
    }

  }
  continueButton() {
    this.paymentArray = {};
    $('.selectedPayment').prop("checked", false);
    $('.make_payment').addClass('active');
    this.continuePressed = true;
    // window.scroll(0,document.getElementById('_subscription_amount').offsetHeight);
  }

  countObjectKey(obj) {
    //  console.log(obj);
    return Object.keys(obj).length;
  }

  onRentItemChange(value,  index, pack: any, group: any) {
    console.log(" Value is : ", value);
    console.log(" selected pack is : ", pack);
    this.rentContinuePressed = false;
    this.selectedRentChargeCode = value;
    this.selectedIndex = index;
    this.paymentArray = {};
    this.selectedPack = pack;
    this.selectedGroup = group;
    this.appliedCoupon = null;
    this.isCouponApplied = false;
    this.selectedPaymentGateway = null;
    this.isRazorpayPayment = false;
    // this.getCouponList();

    let planCodeLabel = 'Buying from my account';
    if (this.chargeCode) {
      planCodeLabel = this.chargeCode;
    }
    this.googleEvent.eventEmitter('cart_selection', 'cart_selection', this.userUnique + "-Selected Plan-" + pack?.price * 1, 0, this.userUnique, this.userUnique + "-Selected Plan-" + pack?.price * 1);

    // this.googleEvent.eventEmitter('Selected Plan - ' + price * 1, "Cart_Selection", this.userUnique + '-' + planCodeLabel, price * 1);
  }
  onPaymentSelected(price, detail, payment_gateway) {
    console.log('payment gateway: ', detail);
    this.selectedPaymentGateway = detail;
    this.isPackRenewable = this.selectedPaymentGateway.SDK_RENEWFLAG === 'Y';
    if (this.isPackRenewable) {
      this.isRenewChecked = true;
    } else {
      this.isRenewChecked = false;
    }
    this.details_error = "";

    this.paymentArray = {
      'amount': price,
      'ActualAmount': price,
      'charge_code': this.chargeCode,
      'pack_code': detail.PM_CHARGECODE,
      'validity': detail.PM_VALIDITY,
      'catlogue': CatlougeTypeConstants.catlogue,
      'plateform': 'web'
    }

    let planCodeLabel = '';
    if (this.chargeCode) {
      planCodeLabel = this.chargeCode;
    }
    this.selected_payment_gateway = payment_gateway;

    this.googleEvent.eventEmitter('payment_gateway_selection', 'payment_gateway_selection', this.userUnique + "-" + payment_gateway + "-" + planCodeLabel + "-" + price * 1, 0, this.userUnique, this.userUnique + "-" + payment_gateway + "-" + planCodeLabel + "-" + price * 1);
    // this.googleEvent.eventEmitter(payment_gateway, "Payment Gateway_Selection", this.userUnique + '-' + planCodeLabel + '-Amount-' + price * 1, price * 1);
    console.log(this.paymentArray);
  }


  placeOrder() {

    if (Object.keys(this.paymentArray).length == 0) {
      this.details_error = "Please select a payment option.";
      return false;
    }

    if (this.selectedPaymentGateway.PACK_TYPE === 'RAZORPAY') {
      this.isRazorpayPayment = true;
      this.getCouponList();
      return;
    }

    this.payButtonText = 'Redirecting...';

    let planCodeLabel = '';
    if (this.chargeCode) {
      planCodeLabel = this.chargeCode;
    }
    this.googleEvent.eventEmitter('payment_gateway_checkout', 'payment_gateway_checkout', this.userUnique + "-" + this.selected_payment_gateway + "-" + planCodeLabel + "-" + this.paymentArray.amount, 0, this.userUnique, this.userUnique + "-" + this.selected_payment_gateway + "-" + planCodeLabel + "-" + this.paymentArray.amount);
    // this.googleEvent.eventEmitter(this.userUnique + ' - ' + this.selected_payment_gateway + ' -Amount- ' + this.paymentArray.amount, "Payment_Checkout", planCodeLabel, this.paymentArray.amount);

    const searchParams = new URLSearchParams();
    Object.keys(this.paymentArray).forEach(key => searchParams.append(key, this.paymentArray[key]));
    console.log(searchParams.toString());

    this.apiService.getData('getpurchaseurlv2?' + searchParams.toString()).subscribe((data: any) => {

      if (data.error_code == '200') {
        console.log(data.result);
        window.location = data.result;


      } else {
        this.details_error = data.error_string;
        this.payButtonText = 'PAY NOW';
      }

    },
      error => {
        this.details_error = 'Something went wrong!! Please try again after some time.';
        this.payButtonText = 'PAY NOW';
      },
      () => {
      }
    );

  }


  applypromoCode() {

    if (this.addPromoForm.invalid) {
      return false;
    }

    let appliedCode = this.addPromoForm.controls.promocode.value;

    // this.googleEvent.eventEmitter(appliedCode, 'Redeem', this.userUnique + ' - Plan Details');

    this.promocode_error = '';
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({ 'couponcode': this.addPromoForm.controls.promocode.value, 'charge_code': '', 'catlogue': this.catlogue ,'platform':'web' });
    this.apiService.postData('activationcode', urlEncodedBodyData)
      .subscribe((data: any) => {
        console.log(data);
        if (data.error_code == '200') {
          this.add_promocodesuccess.nativeElement.click(); // show success
          this.addPromoForm.reset();
        } else {
          this.promocode_error = data.error_string;
        }

      },
        error => {
          this.promocode_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
    this.googleEvent.eventEmitter('payment_gateway_checkout', 'payment_gateway_checkout', this.userUnique + "-" + this.selected_payment_gateway + "-" + appliedCode + "-" + this.paymentArray.amount, 0, this.userUnique, this.userUnique + "-" + this.selected_payment_gateway + "-" + appliedCode + "-" + this.paymentArray.amount);
  }
  goToMyacoount() {
    this.closePromoButton.nativeElement.click(); // close the name field modal
    this.router.navigate(['myaccount']);
  }

  handleBackClick() {
    this.isRazorpayPayment = false;
  }

  payWithRazorpay(paymentObj: any): void {
    if (typeof Razorpay === 'undefined') {
      console.error('Razorpay is not defined');
      return;
    }
    const price: number = this.isCouponApplied ? this.appliedCoupon.final_price : this.selectedPack?.PM_PRICE;
    const checksum: string = this.generateChecksum(price);
    let isPackRenew = (this.isPackRenewable && paymentObj.method !== 'wallet') ? 1 : 0;
    if (paymentObj.method == 'upi') {
      isPackRenew = this.isRenewChecked ? 1 : 0;
    }
    const params = {
      subid: this.isUsernameMobile ? '91' + this.userUnique : this.userUnique, // this.userUnique,
      pricepoint: price,
      package: this.selectedPaymentGateway.PM_CHARGECODE,
      rr: isPackRenew,
      voucher: this.appliedCoupon?.coupon_code || '',
    };
    // if (this.isCouponApplied) {
    //   params['voucher'] = this.appliedCoupon.coupon_code;
    // }
    const urlEncodedParams = this.userService.convertoJSONToUrlEncoded(params);
    const amount: number = (this.isCouponApplied ? this.appliedCoupon.final_price : this.selectedPack.PM_PRICE) * 100;
    this.subscriptionService.getOrderId(checksum, urlEncodedParams).subscribe(
      (orderRes: any) => {
      
        let options = {
          // callback_url: 'https://stage-payments.digivive.com/jocg/callback/phonepe/redirect/Digivive17072388216013700',
          // redirect: true,
          amount: amount, 
          currency: this.selectedPack.CURRENCY,
          email: this.userEmail,
          contact: this.userMobile ? '91' + this.userMobile : '',
          order_id: orderRes.orderId,
        };

        options = {
          ...options,
          ...paymentObj
        }

        var razorpay = new Razorpay({
          key: this.razorpayKey,
          image: 'https://www.prideplex.com/assets/img/logo.png',
        });
        

        razorpay.createPayment(options);
        this.isTransactionProcessing = true;

        razorpay.on('payment.success', (resp) => {
          // console.log(resp);
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0;
          this.transactionSuccessObj = resp;
          this.isTransactionSuccess = true;
          this.isTransactionProcessing = false;
          if (this.isCouponApplied) { 
            this.redeemCoupon();
          }
          // hit the post billing api
          const params = {
            orderId: orderRes.orderId,
            chargedAmount: amount / 100,
            pack: this.selectedPaymentGateway.PM_CHARGECODE,
            packValidty: this.selectedPack.PM_VALIDITY,
            subscriberId: this.userUnique,
            paymentId: resp.razorpay_payment_id
          };
          const urlEncodedParams = this.userService.convertoJSONToUrlEncoded(params);
          // this.subscriptionService.postBilling(urlEncodedParams).subscribe(postBillingRes => {
          //   console.log(postBillingRes);
          // }, (error) => {
          //   console.error('error in postBilling api: ', error);
          // });
        }); 
        
        razorpay.on('payment.error', (resp) => {
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0;
          this.isTransactionFailed = true;
          console.error(resp);
          this.isTransactionProcessing = false;
          this.isCouponApplied = false;
          this.appliedCoupon = null;
          this.cardForm.reset();
          this.selectedCardType = "";
          this.isCardFormSubmitted = false;
          this.upiVpa = null;
          this.netBankingForm.reset();
          this.selectedWallet = null;
        });

      },
      (error) => {
        console.error('Error fetching order id: ', error);
      }
    );
    
  }

  generateChecksum(price: number) {
    let string: string = this.userUnique + '#$#' + price;
    if (this.isUsernameMobile)
      string = '91' + string;

    const keyBytes = new TextEncoder().encode(this.encodingKey);
    const strBytes = new TextEncoder().encode(string);
    const encodedBytes = new Uint8Array(strBytes.length);

    for (let i = 0; i < strBytes.length; i++) {
        encodedBytes[i] = strBytes[i] ^ keyBytes[i % keyBytes.length];
    }

    return btoa(String.fromCharCode.apply(null, encodedBytes));
  }

  getPaymentModes() {
    this.subscriptionService.getPaymentModes().subscribe(paymentModesRes => {
      // console.log('payment modes: ', paymentModesRes);
      this.paymentModes = paymentModesRes.result;
    }, (error) => {
      console.error('error fetching payment modes: ', error);
    });
  }

  getRazorpayPaymentModes() {
    const razorpay = new Razorpay({
        key: this.razorpayKey,
    });

    razorpay.once('ready', (response: any) => {
        this.razorpayPaymentMethods = response.methods;
        console.log('Available payment methods:', response.methods);

        // Map the Razorpay netbanking banks to payment modes netbanking
        const netBankingModes = this.paymentModes.find(item => item.mode?.toUpperCase() === 'NET BANKING');
        const normalizedNetBankingModes = netBankingModes 
            ? netBankingModes[Object.keys(netBankingModes).find(key => key.toUpperCase() === 'NET BANKING')] || [] 
            : [];

        for (const key in this.razorpayPaymentMethods?.netbanking) {
            if (this.razorpayPaymentMethods.netbanking.hasOwnProperty(key)) {
                const bankTitle = this.razorpayPaymentMethods.netbanking[key];
                const bankInfo = normalizedNetBankingModes.find(item => bankTitle.toLowerCase().includes(item.title?.trim().toLowerCase()));
                const imgUrl = bankInfo?.image || "";

                this.netBankingArray.push({
                    title: bankTitle,
                    code: key,
                    image: imgUrl
                });
            }
        }

        this.netBankingArray.sort((a, b) => a.title.localeCompare(b.title));


        // Map the Razorpay wallets banks to payment modes wallets
        const walletModes = this.paymentModes.find(item => item.mode?.toUpperCase() === 'WALLET');
        const normalizedWalletModes = walletModes 
            ? walletModes[Object.keys(walletModes).find(key => key.toUpperCase() === 'WALLET')] || [] 
            : [];

        console.log('walletModes: ', walletModes);
        console.log('paymentModes: ', this.paymentModes);

        for (const key in this.razorpayPaymentMethods?.wallet) {
            if (this.razorpayPaymentMethods.wallet.hasOwnProperty(key)) {
                const matchedWallet = normalizedWalletModes.find(item => {
                    let s = item.title.split(' ')[0].toLowerCase();
                    return key.includes(s);
                });

                const walletTitle = matchedWallet?.title;
                const imgUrl = matchedWallet?.image || "";

                this.walletsArray.push({
                    title: walletTitle,
                    code: key,
                    image: imgUrl
                });
            }
        }

        console.log('walletsArray: ', this.walletsArray);
        // console.log('walletsArray:', this.walletsArray);
    });

    razorpay.once('error', (error: any) => {
        console.error('Error fetching Razorpay payment methods:', error);
    });
  }

  payWithUpi(type: string) {
    this.upiError = '';
    this.upiType = type;
    let params = {
      method: 'upi',
    };
    if (this.upiType === 'collect') {
      const upiRegex = /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/;
      if (!this.upiVpa) {
        this.upiError = "Please enter a UPI ID";
        return;
      }
      if (!this.upiVpa.match(upiRegex)) {
        this.upiError = "Please enter a valid UPI ID";
        return;
      }
      var razorpay = new Razorpay({
        key: this.razorpayKey,
      });
      razorpay.verifyVpa(this.upiVpa)
        .then(() => {
          // VPA is valid, ask the user to click Pay
          params['upi'] = {
            vpa: this.upiVpa,
            flow: 'collect',
          }
          this.payWithRazorpay(params);
        })
        .catch(() => {
          // VPA is invalid, show an error to the user
          this.upiError = "Please enter a valid UPI ID";
        });
    } else {
      params['upi'] = {
        qr: true,
        timeout: 10,
      }
      this.payWithRazorpay(params);
    }
  }


  payWithNetBanking() {
    let payObj = {
      method: 'netbanking',
      bank: this.netBankingForm.value.bank,
    }

    this.payWithRazorpay(payObj);
  }

  onBankChange(bank) {
    this.netBankingForm.get('bank').setValue(bank.code);
  }

  payWithWallet() {
    if (!this.selectedWallet) {
      return;
    }
    let payObj = {
      method: 'wallet',
      wallet: this.selectedWallet.code,
    };

    this.payWithRazorpay(payObj);
  }

  selectWallet(wallet) {
    this.selectedWallet = wallet;
  }

  showCardFrom(cardType: string) {
    this.selectedCardType = cardType;
  }

  hideCardForm() {
    this.selectedCardType = "";
  }

  onCardFormSubmit() {
    this.isCardFormSubmitted = true;
    if (this.cardForm.invalid) {
      console.error('card form invalid: ', this.cardForm);
      return;
    }
    let payObj = {
      method: 'card',
    }
    payObj['card[name]'] = this.cardForm.value.name;
    payObj['card[number]'] = this.cardForm.value.number.replace(/\s/g, '');
    payObj['card[cvv]'] = this.cardForm.value.cvv;
    payObj['card[expiry_month]'] = this.cardForm.value.expiry.split('/')[0];
    payObj['card[expiry_year]'] = this.cardForm.value.expiry.split('/')[1];
    
    this.payWithRazorpay(payObj);
  }

  onCardNumberChange(event: Event): void {
    // console.log('event: ', event);
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value.replace(/\s+/g, ''); // Remove all spaces
    const cursorPosition = inputElement.selectionStart;
  
    // Add spaces after every 4 digits
    value = value.match(/.{1,4}/g)?.join(' ') || value;
  
    this.cardForm.controls.number.setValue(value);
  
    // Adjust cursor position to handle spaces
    if (cursorPosition !== null && cursorPosition !== value.length) {
      const newCursorPosition = cursorPosition + Math.floor(cursorPosition / 4);
      setTimeout(() => inputElement.setSelectionRange(newCursorPosition, newCursorPosition), 0);
    }
  }

  onCardExpiryChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value.replace(/\//g, '');
    const cursorPosition = inputElement.selectionStart;
    const prevLength = inputElement.value.length;
  
    // Format the value
    value = value.match(/.{1,2}/g)?.join('/') || value;
  
    // Set the formatted value to the form control
    this.cardForm.controls.expiry.setValue(value);
  
    // Adjust cursor position
    const newLength = value.length;
    let newCursorPosition = cursorPosition + (newLength - prevLength);
    newCursorPosition = Math.max(0, Math.min(newCursorPosition, newLength));
    inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
  }
  
  preventNonNumeric(event: KeyboardEvent, isExpiry: boolean = false): void {
    console.log(event.key);
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control', 'V', 'v'];
    if (isExpiry) {
      allowedKeys.push('/');
    }
    if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
  
  counter(count: number): any[] {
    return Array(count).fill(0).map((x, i) => i);
  }

  transactionFailureBackClick() {
    this.isTransactionFailed = false;
  }


  getCouponList() {
    const body = {
      pack_code: this.selectedPaymentGateway.PM_CHARGECODE,
      catlogue: this.catlogue,
    };

    const urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(body);
    this.subscriptionService.getCouponList(urlEncodedBodyData).subscribe(couponListRes => {
      // console.log('couponRes: ', couponListRes);
      this.allCoupons = couponListRes.result;
      this.currentCouponsArray = this.allCoupons;
    }, (error) => {
      console.error('error fetching coupons: ', error);
    });
  }

  applyCoupon(coupon: any, input?: string) {
    this.couponError = "";
    if (input === "") {
      this.couponError = 'Please enter a Coupon Code';
      return;
    }
    const params = {
      coupon: input || coupon.coupon_code,
      pack_code : this.selectedPaymentGateway.PM_CHARGECODE,
      // catlogue: this.catlogue,
      // platform: 'web',
    };
    const urlEncodedParams = this.userService.convertoJSONToUrlEncoded(params);
    this.subscriptionService.applyCoupon(urlEncodedParams).subscribe(applyCouponRes => {
      // console.log('applyCouponRes: ', applyCouponRes);
      if (applyCouponRes.error_code == '200') {
        this.appliedCoupon = applyCouponRes.result;
        this.isCouponApplied = true;
        this.isApplyCouponSuccess = true;
        setTimeout(() => {
          this.closeApplyCoupon.nativeElement.click();
          setTimeout(() => {
            this.isApplyCouponSuccess = false;
          }, 1000);
        }, 3000);
      } else {
        this.couponError = applyCouponRes.error_string;
      }
    }, (error) => {
      this.couponError = error.error_string;
      console.error('error applying coupon: ', error);
    });
  }

  removeCoupon() {
    this.appliedCoupon = null;
    this.isCouponApplied = false;
  }

  searchCoupon(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.couponInputValue = value;
    console.log(value);
    console.log(this.currentCouponsArray);
    this.currentCouponsArray = this.allCoupons.filter(item => item?.coupon_code.toLowerCase().includes(value.toLowerCase()));
  }

  redeemCoupon() {
    const body = {
      pack_code: this.selectedPaymentGateway.PM_CHARGECODE,
      coupon_code: this.appliedCoupon.coupon_code,
      order_id: this.transactionSuccessObj?.razorpay_order_id,
      payment_id: this.transactionSuccessObj?.razorpay_payment_id,
      payment_signature: this.transactionSuccessObj?.razorpay_signature,
      purchase_order_date_time: Date.now(),
      user_name: this.userUnique,
    };
    const urlEncodedParams = this.userService.convertoJSONToUrlEncoded(body);
    this.subscriptionService.redeemCoupon(urlEncodedParams).subscribe(redeemCouponRes => {
      console.log('redeemCouponRes: ', redeemCouponRes);
    }, (error) => {
      console.error('error redeeming coupon: ', error);
    });
  }


}
