import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-asset-thumbnail',
  templateUrl: './asset-thumbnail.component.html',
  styleUrls: ['./asset-thumbnail.component.css']
})
export class AssetThumbnailComponent implements OnInit {

  @Input() data: any;
  cateoryname: any;
  constructor() { 
    this.cateoryname = this.data;
  }

  ngOnInit(): void {
  }

}
