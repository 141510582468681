import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountComponent } from './account.component';
import { AccountService } from './account.service';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


const routes: Routes = [
    {
        path: '',
        children: [
            { path: '', component: AccountComponent }
        ]
    }
];

@NgModule({
  declarations: [AccountComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    //SharedModule,
    FormsModule, ReactiveFormsModule,
    // BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [RouterModule,
    //SharedModule
  ],
  providers:[AccountService]
})
export class AccountModule { }
