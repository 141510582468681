<video id="player_one" class="video-js vjs-fluid" controls preload="auto" width="640" height="360" playsinline autoplay>
    <!--   autoplay -->


    <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video
        </a>
    </p>
</video> 
