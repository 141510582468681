import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors, UserService } from '../../../core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { EmailService } from '../../../core/services/email.service';
import { PlatformLocation } from '@angular/common';
import { UserTypeConstants,CatlougeTypeConstants } from 'src/app/core/constants/userType.constant';


@Component({
  selector: 'app-verifyotp',
  templateUrl: './verifyotp.component.html',
  styleUrls: ['./verifyotp.component.css']
})
export class VerifyotpComponent implements OnInit {
    evalUser:boolean=false;
    verifyForm: FormGroup;
    errors: { errors: {}; };
    @ViewChild('closebutton') closebutton;
  verifySuccessMsg: any;
  
    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private userService: UserService,
      private formBuilder: FormBuilder,
      private toastr: ToastrService,
      private elementRef: ElementRef,
      private emailService: EmailService,
      private platformLocation: PlatformLocation,
      private fb: FormBuilder,
  
    ) {
    }
  
    ngOnInit() {
  
      if (localStorage.getItem('isAuthenticated')) {
        this.router.navigateByUrl('/');
      }
      this.verifyForm = this.fb.group({
        'otp': ['', Validators.required],
        'mobile': ['', Validators.required],
        'catlogue': [CatlougeTypeConstants.catlogue , Validators.required],
      });
  
    }
  
    verifyOtp(){
  
      this.errors = { errors: {} };
      const credentials = this.verifyForm.value;
      let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(credentials);    
      this.userService.verifyOtp(urlEncodedBodyData).subscribe(data => {
        console.log(data);
        this.verifySuccessMsg = 'Verified'

        setTimeout(()=>{
          console.log(data);
          // localStorage.setItem('userInfo', data)
           this.closebutton.nativeElement.click();
           this.router.navigateByUrl('/home');
        }, 4000);
  
      }, err => {
        this.errors = err;
      });
  
    }
  
  }
  