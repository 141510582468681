import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SearchComponent } from './search.component';
import { SearchService } from './search.service';

import { Routes, RouterModule } from '@angular/router';
import { NgAisModule } from 'angular-instantsearch';
  
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SharedModule } from 'src/app/shared/shared.module';
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


const routes: Routes = [
    {
        path: '',
        children: [
            { path: '', component: SearchComponent }
        ]
    }
];

@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    NgAisModule.forRoot(),
    CarouselModule,
    SharedModule,
    NgxPaginationModule,
    NgbModule
    
  ],
  exports: [RouterModule],
  providers:[SearchService]
})
export class SearchModule { }
