<div class="maincontentarea mt20">
    <div class="container">
       <div class="row">
           <div class="col-sm-12">               
               <div class="_advertise_content">
                    <h2>
                        why advertise with us
                    </h2>
                    <div class="_advertise_img">
                        <img src="assets/img/adverimg.png" alt="advertise-img">
                    </div>                    
               </div>
           </div>
       </div>
    </div>
   </div>