
<!-- <video id="player_one" class="video-js vjs-fluid" controls preload="auto"  width="640" height="360" playsinline poster="https://res.cloudinary.com/digivive/image/upload/v1466099253/Swanandi_Reveals_5_Things_Likes_And_Dislikes_About_Husband.jpg">
	<source src="//opencdn.b-cdn.net/video/demo_240.mp4" res="240" label="240p" type="video/mp4">
        <source src="//opencdn.b-cdn.net/video/demo_360.mp4" res="360" label="360p" type="video/mp4">
        <source src="//opencdn.b-cdn.net/video/demo_480.mp4" res="480" label="480p" default type="video/mp4">
        <source src="//opencdn.b-cdn.net/video/demo_720.mp4" res="720" label="720p" type="video/mp4">
</video> -->

 <!-- <source
        src="https://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8"
        type="application/x-mpegURL"> -->
<!-- <video id="player_one" class="video-js vjs-fluid" controls preload="auto"  width="640" height="360" playsinline  autoplay>
               

     <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">
          supports HTML5 video
        </a>
      </p>
</video> -->

<video id="player_one" class="video-js vjs-fluid" controls preload="auto"  width="640" height="360" playsinline>
  <!--   autoplay -->
             

  <p class="vjs-no-js">
     To view this video please enable JavaScript, and consider upgrading to a
     web browser that
     <a href="https://videojs.com/html5-video-support/" target="_blank">
       supports HTML5 video
     </a>
   </p>

  <!-- <div class="_skip_intro">
    <button type="button" class="btn btn-primary">Skip Intro</button>
 </div> -->
</video>
<a data-toggle="modal" data-target="#showError" #showErrorMsg></a>
<div class="modal auth _premium_msg" id="showError">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <button type="button" #closeShowErrorMsg (click)="removeErrorBox()" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="success-add">
          <p>{{error}}</p>
        </div>

        <div class="action-btn">
          <div>
            <button type="button" class="btn btn-primary yes" (click)="removeErrorBox()">Ok</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- 
https://www.npmjs.com/package/ngx-dfp
https://stackblitz.com/edit/angular-fftqvj?file=src%2Fapp%2Fpage%2Fpage.component.html 
https://github.com/atwwei/ngx-dfp/blob/master/src/app/page/page.component.ts
-->

<!-- <dfp-video width="640" height="625" [adActions]="adInput" (adEvents)="adEvent($event)" adTag="https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=vmap&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ar%3Dpremidpost&cmsid=496&vid=short_onecue&correlator=">

  <video id="player_one" class="video-js vjs-fluid" controls preload="auto"  width="640" height="360" playsinline >
    <p class="vjs-no-js">
       To view this video please enable JavaScript, and consider upgrading to a
       web browser that
       <a href="https://videojs.com/html5-video-support/" target="_blank">
         supports HTML5 video
       </a>
     </p>
</video>
</dfp-video>
 <div class="ad-container" (click)="adInput.emit('play')"></div> -->
