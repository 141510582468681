import {
  Component,
  OnInit,
  Input,
  Output
} from '@angular/core';
import {
  VideoPlayerService
} from "src/app/core/services/videoPlayerService";
import {
  UserService
} from "src/app/core/services/user.service";
import {
  jwPlayerService
} from "src/app/views/jw-video-player/jwplayer.servies";
import {
  Analyticsstreaminglog,
  CatlougeTypeConstants
} from "src/app/core/constants/index";
import {
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import {
  EventTrackingService
} from 'src/app/services/google/event-tracking.service';
import {
  LocationStrategy
} from '@angular/common';


declare var jwplayer;
declare var $: any;

@Component({
  selector: 'app-jw-video-player',
  templateUrl: './jw-video-player.component.html',
  styleUrls: ['./jw-video-player.component.css'],
  providers: [NgbActiveModal]
})
export class JwVideoPlayerComponent implements OnInit {

  @Input() streamInfo: any;
  playermodal: any;
  totalDuration: any;
  seekTime: any;
  userInfo: any;
  sessionId: string;
  title: string = '';
  videoPlayUrl: any;

  userUnique: string = 'NA';
  error_string: any;

  constructor(
      public VideoPlayerService: VideoPlayerService,
      public UserService: UserService,
      public jwPlayerService: jwPlayerService,
      public activeModal: NgbActiveModal,
      private googleEvent: EventTrackingService,
      private location: LocationStrategy
  ) {

      history.pushState(null, null, window.location.href);
      this.location.onPopState(() => {
          console.log('backButtonPressed')
          this.mobileBrowserBackbutton();

      });
  }

  ngOnInit(): void {

      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.sessionId = localStorage.getItem('sessionId');
      console.log('this.userInfo ==>> ' + Object.keys(this.userInfo.result).length);
      console.log(this.userInfo);
      if (Object.keys(this.userInfo.result).length > 0) {
          if (this.userInfo.result.register_by == 'mobile') {
              this.userUnique = this.userInfo.result.mobile;
          } else {
              this.userUnique = this.userInfo.result.emailaddress;
          }
      }


      this.streamInfo = this.streamInfo;
      console.log(this.streamInfo);
      let videoStreamObj = {
          "nmode": "WIFI",
          "pushId": '',
          "ct": "1",
          "build": 10000,
          "charge_code": this.streamInfo.code,
          "appv": "1.0.00",
          "push_date": "",
          "air": "airip"
      }
      let urlEncodedStreamInfo = this.UserService.convertoJSONToUrlEncoded(videoStreamObj);

      this.VideoPlayerService.playUrl(urlEncodedStreamInfo).subscribe(playObj => {
          let noerror = 1;
        console.log(playObj);
          if(playObj['error_code'] == '200') {

            this.videoPlayUrl = playObj.result;
            console.log(playObj.assetData.name);
            console.log(playObj.assetData.channel_name);
  
            if (playObj.assetData.name != undefined)
                this.title = playObj.assetData.name;
            else
                this.title = playObj.assetData.channel_name;
  
            console.log(this.title);
  
            this.googleEvent.eventEmitter("Buffering", "Video_view", this.userUnique + ' - ' + this.title +  this.streamInfo.type + ' - ' + videoStreamObj.charge_code, 0 , this.userUnique, this.userUnique + ' - ' + this.title +  this.streamInfo.type + ' - ' + videoStreamObj.charge_code );
            this.googleEvent.eventEmitter("Buffering", "User_Video", videoStreamObj.charge_code + ' - ' + this.streamInfo.type + ' - ' + this.title, 0 , this.userUnique , videoStreamObj.charge_code + ' - ' + this.streamInfo.type + ' - ' + this.title);
  
            this.playermodal = jwplayer("player_container_modal").setup({
                "file": playObj.result,
                "repeat": false,
                "height": "100%",
                "width": "100%",
                mute: false,
                aspectratio: "40:20",
                volume: 25,
                autostart: false,
                displaytitle: false,
                displaydescription: false,
                displayHeading: true,
                title: playObj.assetData.name,
                description: ''
            });
            console.log(playObj);
  
            this.playermodal.on('play', (data) => {
  
                this.googleEvent.eventEmitter("Playing", "Video", this.userUnique + ' - ' + this.streamInfo.type + ' - ' + videoStreamObj.charge_code +'-' + this.title, 0 , this.userUnique + ' - ' + this.streamInfo.type + ' - ' + videoStreamObj.charge_code +'-' + this.title);
  
                this.googleEvent.eventEmitter("Playing",  "User_Video", videoStreamObj.charge_code + ' - ' + this.streamInfo.type + ' - ' + this.title, 0 , this.userUnique, videoStreamObj.charge_code + ' - ' + this.streamInfo.type + ' - ' + this.title);
  
                console.log(data);
            });
  
            this.playermodal.on('pause', (data) => {
                // $('.modal .modal-xl .modal-content').css('height', '99%!important');
  
                // $('.modal .modal-xl .modal-content').attr('style', 'height: 99% !important');
                // this.stopVideo();
  
                this.googleEvent.eventEmitter("Paused", "Video", this.userUnique + ' - ' + this.streamInfo.type + ' - ' + videoStreamObj.charge_code , 0, this.userUnique , this.userUnique + ' - ' + this.streamInfo.type + ' - ' +videoStreamObj.charge_code);
  
                this.googleEvent.eventEmitter("Paused", "User_Video", videoStreamObj.charge_code + ' - ' + this.streamInfo.type + ' - ' + this.title, 0 ,this.userUnique , videoStreamObj.charge_code + ' - ' + this.streamInfo.type + ' - ' + this.title);
  
            });
  
  
            this.playermodal.on('complete', (data) => {
  
                this.googleEvent.eventEmitter("Completed", "Video", this.userUnique + ' - ' + this.streamInfo.type + ' - ' + videoStreamObj.charge_code +'-' + this.title, 0, this.userUnique, this.userUnique + ' - ' + this.streamInfo.type + ' - ' + videoStreamObj.charge_code +'-' + this.title);
  
                this.googleEvent.eventEmitter("Completed", "User_Video", videoStreamObj.charge_code + ' - ' + this.streamInfo.type + ' - ' + this.title, 0, this.userUnique, videoStreamObj.charge_code + ' - ' + this.streamInfo.type + ' - ' + this.title);
  
  
                this.jwPlayerService.complete('').subscribe((res => {
  
                }))
  
            });
  
  
            this.playermodal.on('setupError', () => {
                noerror = 0;
            });
  
            this.playermodal.on('ready', () => {
  
                noerror = 0;
                this.playermodal.seek(Math.round(this.streamInfo.stop));
  
                let backicon = '<svg xmlns="http://www.w3.org/2000/svg" class="jw-svg-icon jw-svg-icon-prev" viewBox="0 0 240 240"><path transform="translate(240, 0) scale(-1, 1) " d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111  C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587  c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z" fill="#ffffff" data-original="#1e201d"></path></svg>';
                this.playermodal.addButton(
                    backicon,
                    'Back',
                    (e) => {
                        this.playermodal.remove();
                        this.activeModal.close();
  
                        try {
                            this.disableModal();
                        } catch (e) {
  
                        }
                    },
                    'back-button',
                    'jw-icon-backbutton'
                );
  
                // $('.jw-title .jw-title-primary').before($('.jw-icon-backbutton'));
                $('.jw-overlays').before($('.jw-icon-backbutton'));
                console.log('this.title ' + this.title);
                $('.jw-spacer').text(this.title);
  
            });
  
  
  
            // next video
            // let icon = '<svg xmlns="http://www.w3.org/2000/svg" class="jw-svg-icon jw-svg-icon-next" viewBox="0 0 240 240"><path  d="M165,60v53.3L59.2,42.8C56.9,41.3,55,42.3,55,45v150c0,2.7,1.9,3.8,4.2,2.2L165,126.6v53.3h20v-120L165,60L165,60z"></path></svg>';
            // // The following code uses the addButton function from the JW Player API.
            // this.playermodal.addButton(
            //   icon,
            //   'Next',
            //   () => {
            //     let videoStreamObj = {
            //       "nmode": "WIFI",
            //       "pushId": '',
            //       "ct": "1",
            //       "build": 10000,
            //       "charge_code": 'MOV4004',
            //       "appv": "1.0.00",
            //       "push_date": "",
            //       "air": "airip"
            //     }
            //     let urlEncodedStreamInfo = this.UserService.convertoJSONToUrlEncoded(videoStreamObj);
            //     this.VideoPlayerService.playUrl(urlEncodedStreamInfo).subscribe(playObj => {
  
            //       this.playermodal.remove();
            //       this.playermodal = jwplayer("player_container_modal").setup({
            //         "file": playObj.result,
            //         "repeat": true,
            //         "height": "100%",
            //         "width": "100%",
            //         mute: false,
            //         aspectratio: "40:20",
            //         volume: 25,
            //         autostart: false,
            //         displaytitle: true,
            //         title: 'Test_video_new'
            //       });
  
            //     });
  
            //   },
            //   'previous',
            //   'jw-icon-prev'
            // );
            // next video end
            this.playermodal.on('remove', (data) => {
  
                //this.googleEvent.eventEmitter("Closed - " + videoStreamObj.charge_code, "Video", this.userUnique+ ' - '+this.streamInfo.type + ' - ' + this.title);
  
  
                this.googleEvent.eventEmitter("Closed", "Video", this.userUnique + ' - ' + this.streamInfo.type + ' - ' + videoStreamObj.charge_code + '-' + this.title, 0 ,this.userUnique, this.userUnique + ' - ' + this.streamInfo.type + ' - ' + videoStreamObj.charge_code + '-' + this.title);
  
                this.googleEvent.eventEmitter("Closed", "User_Video", videoStreamObj.charge_code + ' - ' + this.streamInfo.type + ' - ' + this.title , 0, this.userUnique , videoStreamObj.charge_code + ' - ' + this.streamInfo.type + ' - ' + this.title);
  
  
                console.log(data);
                noerror = 0;
                this.stopVideo()
            });
  
  
            this.playermodal.on('beforePlay', (data) => {
                noerror = 0;
                this.playermodal.seek(this.seekTime);
                console.log(data);
            });
  
            this.playermodal.on('beforeComplete', (data) => {
                noerror = 0;
                console.log(data);
            });
  
            this.playermodal.on('bufferChange', (data) => {
                console.log(data);
            });
  
  
  
            if (noerror) {
                let buffDurationStatus = 0
                this.playermodal.on('bufferChange', () => {
                    if (buffDurationStatus == 0) {
                        this.totalDuration = this.playermodal.getDuration();
                        //seek video
                        if (this.totalDuration > 0) {
                            console.log("this.seekTime---" + this.seekTime);
                            if (this.seekTime > 0 && this.seekTime < 98) {
                                this.seekTime = parseFloat(((this.seekTime / 100) * this.totalDuration).toFixed(0));
                                this.playermodal.seek(this.seekTime);
                            }
                        }
                        buffDurationStatus = 1;
                    }
                });
  
            }


          } else {
            this.error_string = playObj['error_string'];
            console.log(this.error_string);
          }



      });

  }



  stopVideo() {
      console.log(this.streamInfo);
      let playLogReqdata = Analyticsstreaminglog.logData;
      playLogReqdata.charge_code = this.streamInfo.charge_code;
      playLogReqdata.platform = "web";
      playLogReqdata.code = this.streamInfo.code;
      playLogReqdata.catlogue = CatlougeTypeConstants.catlogue;
      playLogReqdata.play_url = this.videoPlayUrl;
      playLogReqdata.asset_code = this.streamInfo.code;
      playLogReqdata.category = this.streamInfo.code;
      playLogReqdata['last_video_play_id'] = this.streamInfo.asset_mongo_id;
      playLogReqdata['release_date'] = this.streamInfo.release_date ? this.streamInfo.release_date : '';
      playLogReqdata['name'] = this.streamInfo.name ? this.streamInfo.name : this.streamInfo.tvshow_name;
      playLogReqdata['cloud_image'] = this.streamInfo.thumbnail;
      playLogReqdata['content_availability'] = this.streamInfo.content_availability;
      playLogReqdata['asset_mongo_id'] = this.streamInfo.asset_mongo_id;

      playLogReqdata['sec'] = this.streamInfo.sec;
      playLogReqdata['min'] = this.streamInfo.min;
      playLogReqdata['hours'] = this.streamInfo.hours;
      playLogReqdata['genre_text'] = this.streamInfo.genre_name;
      playLogReqdata['languages'] = this.streamInfo.languages;
      playLogReqdata['genre'] = this.streamInfo.genre[0],
          playLogReqdata.start = 0;
      playLogReqdata.mobile = this.userInfo.result.mobile;
      playLogReqdata.stop = this.playermodal.getPosition();
      playLogReqdata['duration'] = this.playermodal.getDuration();
      playLogReqdata['type'] = this.streamInfo.type;
      playLogReqdata['uid'] = this.sessionId;
      playLogReqdata['synopsis'] = this.streamInfo.synopsis ? this.streamInfo.synopsis : '';

      let urlEncodedPlayData = this.UserService.convertoJSONToUrlEncoded(playLogReqdata);
      this.jwPlayerService.pause(urlEncodedPlayData).subscribe((res => {

      }))

      console.log(this.videoPlayUrl);
      let cs_uri_query = this.videoPlayUrl.split('?')[1]
      let cs_uri_stem = this.videoPlayUrl.split('?')[0]
      console.log(cs_uri_query);
      let videoLogData = {
          "x-app": this.streamInfo.type == 'livetv' ? 'livetv' : 'vod',
          "x-event": 'stop',
          "sc-bytes": Math.round(this.playermodal.getPosition()),
          "c-user-agent": "platform/web/PlayerVersion-2.7.3",
          "x-duration": Math.round(this.playermodal.getPosition()),
          "x-sname": this.streamInfo.name ? this.streamInfo.name : this.streamInfo.tvshow_name,
          "cs-uri-query": encodeURIComponent(cs_uri_query.toString()),
          "cs-uri-stem": cs_uri_stem
      }
      console.log(videoLogData);


      let urlEncodedEncodeParams = this.UserService.convertoJSONToUrlEncoded(videoLogData);

      console.log(urlEncodedEncodeParams + '&' + cs_uri_query);
      this.jwPlayerService.analyticsLog(urlEncodedEncodeParams).subscribe((res => {

      }))

  }



  closeModal() {
      console.log('close modal');
      this.playermodal().stop();
  }


  disableModal() {
      document.querySelector("ngb-modal-window").classList.remove("show");
      document.querySelector("ngb-modal-backdrop").classList.remove("show");
      document.getElementById('nexgTV-app').click();
      document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
      document.querySelectorAll(".d-block").forEach(el => el.remove())
      document.body.style.overflow = "scroll";
  }
  mobileBrowserBackbutton() {
      this.playermodal.remove();
      this.disableModal()
  }
}