<header *ngIf="!isVouchersPage && !isHoroscopePage && !isMandirPage && !isPanchang && !isAppsPages">
  <nav class="navbar navbar-expand-md fixed-top" *ngIf="isRedeemPage">
    <div class="container">
      <a (click)="clickWithRedeem()" class="logo_center_mobile"><img src="assets/img/logo.png" /></a>
    </div>

  </nav>

  <nav class="navbar navbar-expand-md fixed-top " *ngIf="!isRedeemPage">
    <div class="container">
      <a (click)="gotToTab()" class="navbar-brand"><img src="assets/img/logo.png" /></a>
      <!-- <a class="navbar-brand" href="#">
        <img src="assets/img/white_digivive.png" alt="logo" (click)="gotToTab()">
      </a> -->

      <span class="subscribe-img subscribe-mbl" *ngIf="isAuthenticated == true"><a routerLink="/subscribe/chooseplan"><img
        src="assets/img/subscribe_button.png" /></a>
      </span>
      <span class="subscribe-img subscribe-mbl" *ngIf="isAuthenticated == false"><a (click)="openLoginModal(content)"><img
        src="assets/img/subscribe_button.png" /></a></span>

      <form class="form-inline my-3 my-md-0 mobile-search">
        <ul class="form_right">
          <li>
            <form (ngSubmit)="submitMobileSearch();">
              <input class="form-control" [value]="searchQuery" (keyup)="searchResult($event)" type="text"
                placeholder="Search" name="mobileSearchTerm" [(ngModel)]="mobileSearchTerm" autocomplete="off">
              <i class="fa fa-search search_pointer" (click)="submitMobileSearch();" aria-hidden="true"></i>
            </form>
          </li>
          <li class="hide-search">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04"
              aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon">
                <i class="fa fa-bars" aria-hidden="true"></i>
              </span>
            </button>
          </li>
        </ul>
      </form>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04"
        aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation"
        style="display:none !important;">
        <span class="navbar-toggler-icon">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarsExample04">
        <ul #tabContainer class="navbar-nav mr-auto">
          <li class="nav-item" *ngFor="let item of visibleTabs; let i=index"
            [ngClass]="{'active': slugName == item.slug}">
            <a class="nav-link" (click)="highLightMenu(item.slug,item.name)" routerLinkActive="active">{{item.name}}
            </a>
          </li>

          <li *ngIf="hiddenTabs?.length > 0" class="nav-item" style="position: relative;">
            <a class="nav-link" href="javascript:;">More <i class="fa fa-angle-down"></i></a>
            <ul class="sub-menu">
              <li class="nav-item" *ngFor="let item of hiddenTabs; let i=index"
                [ngClass]="{'active': slugName == item.slug}">
                <a class="nav-link" (click)="highLightMenu(item.slug,item.name)" routerLinkActive="active">{{item.name}}
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <form class="form-inline my-3 my-md-0">
          <ul class="form_right mobile-display-block">
            <li class="desktop-search">
              <form (ngSubmit)="submitSearch();">
                <input autocomplete="off" class="form-control searchVal" [value]="searchQuery" (keyup)="searchResult($event)" type="text"
                  placeholder="Search" #searchTerm id="searchVal" (keyup.enter)="clearSearchData()">
                <i class="fa fa-search search_pointer" (click)="submitSearch();" aria-hidden="true"></i>
              </form>
              <div class="suggestion_search1" *ngIf="isTypeSonse==1 && checkSerchKey.length>2">
                <ais-instantsearch [config]="config1" >
                  <ais-configure [searchParameters]="allsearchParameters1"></ais-configure>

                  <ais-hits>
                    <ng-template let-hits="hits" let-results="results">



                      <ul class="suggestion_search">


                        <li *ngFor="let hit of hits">
                          <a style="color:red" (click)="searchWithField(hit.name)">{{hit.name}}</a>
                        </li>
                      </ul>
                    </ng-template>
                  </ais-hits>
                </ais-instantsearch>
              </div>

            </li>
            <li class="subscribe-img subscribe-desktop" *ngIf="isAuthenticated == true"><a routerLink="/subscribe/chooseplan"><img
                  src="assets/img/subscribe_button.png" /></a>
            </li>
            <li class="subscribe-img subscribe-desktop" *ngIf="isAuthenticated == false"><a (click)="openLoginModal(content)"><img
                  src="assets/img/subscribe_button.png" /></a></li>

            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="dropdown04" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <!-- <img src="assets/img/my_profile.png" alt="myprofile"> -->
                <span *ngIf="isAuthenticated == false" class="user_i">{{ 'Guest'}}</span>
                <span style="margin-top: 0;" *ngIf="isAuthenticated" class="user_i"><img src="../../../../assets/img/user_icon.png"></span>

                <i class="fa fa-chevron-down" aria-hidden="true"></i>
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdown04">
                <div *ngIf="isAuthenticated == false">
                  <a class="dropdown-item" data-toggle="modal" (click)="openSignupModal(signupContent)">Sign Up</a>
                  <a class="dropdown-item" (click)="openLoginModal(content)" #icl>Login</a>
                </div>
                <div *ngIf="isAuthenticated == true">
                  <button type="button" [hidden]="true" class="dropdown-item" (click)="openTellUs(tellUsContent)"
                    #openTellUsModel>Tell Us Modal</button>
                  <a class="dropdown-item" (click)="closePlayerModal()" routerLink="/myaccount"
                    routerLinkActive="active" aria-disabled="true">Account</a>
                  <a class="dropdown-item" (click)="closePlayerModal()" routerLink="/playlist" routerLinkActive="active"
                    aria-disabled="true">Watchlist</a>
                  <!-- <a class="dropdown-item" (click)="closePlayerModal()" [routerLink]="['/guruji/guruji-categories']" routerLinkActive="active" aria-disabled="true">Favourites</a>
                  <a class="dropdown-item" (click)="gotoPnachang()" routerLinkActive="active" aria-disabled="true">Panchang</a>
                  <a class="dropdown-item" [routerLink]="['/vouchers']" routerLinkActive="active" aria-disabled="true">My Vouchers</a>
                  <a class="dropdown-item" (click)="gotoHoroscope()" routerLinkActive="active" aria-disabled="true">HoroScope</a> -->
                  <a class="dropdown-item" (click)="logout();closePlayerModal()">Logout</a>
                </div>
                <!-- <a class="dropdown-item" href="#">Something else here</a> -->
                <!-- <a class="dropdown-item" href="#">Something else here</a> -->
                <!-- <a class="dropdown-item" href="#">Something else here</a> -->
              </div>


            </li>
          </ul>
        </form>
      </div>
    </div>

  </nav>
</header>

<ng-template #content let-modal>
  <app-login (newItemEvent)="addItem($event)"></app-login>
</ng-template>

<ng-template #signupContent let-modal>
  <app-register></app-register>
</ng-template>

<ng-template #tellUsContent let-modal>
  <app-tell-us></app-tell-us>
</ng-template>