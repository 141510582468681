<!-- Episode list -->

<section class="my_subscription">
    <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <h4 class="accmain-head">Subscription</h4> 
             
            <!-- Choose plan -->
            
              <div class="_choose_plan">
                  <ul>
                      <li class="active">
                          <p>Choose Plan</p>
                          <span class="_S_circle"></span>
                      </li>
                      <li class="active">
                          <p>Make Payment</p>
                          <span class="_S_circle"></span>
                      </li>
                      <li>
                          <p>Confirmation</p>
                          <span class="_S_circle"></span>
                      </li>
                  </ul>
              </div>  
     
              <!-- Subscription amount -->
          <div class="_subscription_payment">
              <div class="_subscription_second">
                  <div class="_plan_selection">                    
                      <div class="_amount_data">                                                                                                                              
                          <div class="_plan_list">
                              <div class="_r_value">
                              <img src="/assets/img/rupees.png">
                              <h2>
                                  35
                              </h2>
                              </div>
                              <h6>
                                  Weekly plan
                              </h6>
                              <p>You will be charged ` 35 after every week</p>
                          </div>                        
                      </div> 
                  </div>
              </div> 
  
              <div class="_subscription_card">
                  <div class="payment_detail">
                      <form>
                          <div class="form-group">                          
                            <input type="text" class="form-control" placeholder="Credit/ Debit Card">
                            <img src="/assets/img/credit_card_digit.png"> 
                          </div>
                          <div class="form-group">                          
                              <input type="text" class="form-control" placeholder="PayTm Wallet">
                              <img src="/assets/img/paytm_code.png"> 
                          </div>
                        </form>
                  </div>
              </div> 
              
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <!--End Episode list -->
  
  <!-- <section class="banner_img add">
      <div class="container">
         <div class="row">
             <div class="col-sm-12">
                 <div class="p_banner">
                    <img src="/assets/img/advertisement.jpg">
                 </div>
             </div>
         </div>
      </div>
    </section> -->