<!-- <app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer> -->

<!-- Registration -->
<!-- <div class="modal auth" id="register">
  <app-register></app-register>
</div> -->


<!-- <div class="modal auth" id="login">
  <app-login></app-login>
</div> -->


<!-- Login -->
<!-- <div class="modal auth" id="login">
    <app-login></app-login>
</div> -->


<div class="modal auth" id="verifyOtp">
  <app-verifyotp></app-verifyotp>
</div><!--Reset-->
<!--Registration-->