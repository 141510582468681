import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { map } from 'rxjs/operators';
import { JwtService } from 'src/app/core';
import { CatlougeTypeConstants } from "src/app/core/constants/index";

@Injectable()
export class livetvService {
//   private currentUserSubject = new BehaviorSubject(false);
//   public currentUser = this.currentUserSubject.asObservable();
userInfo:any;
catlouge:any;
  constructor(
    private apiService: ApiService,
    private jwtService: JwtService
  ) { }

  getTvshowPageAssets(params){
      if (this.jwtService.getToken()) {
        // this.userInfo = JSON.parse(this.userInfo);
        this.catlouge = localStorage.getItem('sessionId');
        this.catlouge = CatlougeTypeConstants.catlogue;   
        if(this.userInfo){
        }
        return this.apiService.get(`genrewithassets?${params}`).pipe(map(data => {
          return data;
        }));
      }
  }

  getEpgData(epgReqParams){
    return this.apiService.get(`epg?${epgReqParams}`).pipe(map(data => {
      return data;
    }));

  }

  getLanguageList(languageListParams){
    return this.apiService.get(`language?${languageListParams}`).pipe(map(data => {
      return data;
    }));

  }



}
