import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinalDate',
  pure: true
})
export class ordinalDate implements PipeTransform {

  transform(value:any): any {
    return value.replace( /(\d)(st|nd|rd|th)/g, '$1<sup>$2</sup>');
  }

}