import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class SearchService {
  END_POINT = environment.api_url;
  isParentalControlEnabled = null;
  constructor() { }

  setParentalControl(status){
    this.isParentalControlEnabled = status;
    localStorage.setItem('parentControl', this.isParentalControlEnabled);
  }

   parentalControlStatus(){
    // console.log(this.isParentalControlEnabled);
    // return this.isParentalControlEnabled;
let parentControl = localStorage.getItem('parentControl');;
    return parentControl;
  }

  elsticSearchQuery(){
    // return this.apiService.post('playlist', params).pipe(map(data => {
    //   return data;
    // }));

  }
  
}
