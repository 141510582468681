<div class="maincontentarea" style="min-height: unset;">
    <div class="container">
        <div class="row">
            <div class="_page_not_found">
                <div class="row">
                    <div class="col-md-5 col-sm-12">
                        <div class="_404_img">
                            <img class="404"  src="assets/img/500.png">
                            <h2>Oops!</h2>
                            <P>Something went wrong</P>
                        </div>
                    </div>
                    <div class="col-md-7 col-sm-12 content">
                         <div class="_404_content">
                             <p>Oops! Something went wrong. The server encountered an internal error  
                            <br>or misconfiguration and was unable to complete your request</p>
                                <p class="code">ahdi7-58897hhhqwyy@iowioek789989087server</p>
                                <p class="refresh">Try <a href="javascript:;" (click)="previousPage()">refreshing</a> the page or <a href="javascript:void(0);" routerLink="/contactus">Contact Us</a> for additional information</p>
                         </div>
                    </div>
                </div>
        </div>
        </div>
    </div>
</div>