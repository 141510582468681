import { Injectable } from '@angular/core';
import { Observable, throwError} from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/core';

@Injectable()
export class PlayListService {
  END_POINT = environment.api_url;
  constructor(
    private apiService: ApiService,
    ) { }

  addToPlayList(params){

    return this.apiService.post('playlist', params).pipe(map(data => {
      return data;
    }));

  }

  getPlaylist(params){

    return this.apiService.get(`playlist?${params}`).pipe(map(data => {
      return data;
    }));
  
  }


  deleteFromPlayList(params){

    return this.apiService.post('playlist', params).pipe(map(data => {
      return data;
    }));
  
  }


  
}
