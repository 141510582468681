<div class="maincontentarea mt20">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">               
                <div class="_about_content">
                    <h2>About us</h2>
                    <div class="_about_inner_c">                        
                        <img class="_about_img" src="assets/img/logo.png" alt="Prideplex">                        
                        <p>Prideplex is the most happening Premium OTT service for Assamese language audience where you can enjoy music and poems, watch celebrity interviews, music videos, movies and Assamese web series.</p>
                        <p>Prideplex is a venture of Pride East Entertainments Private Limited, the leading regional media house connecting the entire north east region of India. It is the first hybrid OTT platform in North-East India which gives you a complete different experience of entertainment.</p>
                        <p>The ambitious project includes a number of mega series by leading and veteran professionals of the industry. You can also enjoy a number of popular songs, Assamese films and some evergreen poems. It also aims to bring back the memories associated with our folk tales. Popular 'Silonir jiyekor sadhu' from the collection of 'Burhi aair sadhu' by literary doyen Lakshminath Bezbarua has been made available. Other such tales will be available soon in podcasts.</p>
                        <p>For more information about Pride East, visit <a target="_blank" href="https://prideeast.com">www.prideeast.com</a></p>
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>