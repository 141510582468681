
<div class="maincontentarea" style="min-height: unset;">
    <div class="container">
        <div class="_page_not_found">
                <div class="row">
                    <div class="col-md-5 col-sm-12">
                        <div class="_404_img">
                            <img class="404"  src="assets/img/404.png">
                            <h2>Oops!</h2>
                            <P>This page isn't available</P>
                        </div>
                    </div>
                    <div class="col-md-7 col-sm-12 content">
                         <div class="_404_content">
                             <p>Oops! This page isn't available. Seems like the link you
                                  followed in broken or the page has been removed. </p>
                                <p class="code">ahdi7-58897hhhqwyy@iowioek789989087server</p>
                                <button class="btn btn-default auth-btn backhome" routerLink="/">Go to home</button>
                                <a href="javascript:;" (click)="previousPage()">Retry</a>
                         </div>
                    </div>
                </div>
        </div>
    </div>
</div>
