import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  SimpleChanges,
  Output, EventEmitter,
  OnDestroy
} from "@angular/core";
import { OwlOptions,CarouselComponent } from "ngx-owl-carousel-o";
import {
  customOptionsForDaysSlider,
  customOptionsForTimeSlider,
} from "src/app/shared/owl-slider-config";
import { CatlougeTypeConstants, tempLiveData } from "src/app/core/constants";
import { AssetsService } from "src/app/core/services/assets.service";
import * as _ from "lodash";
// import * as moment from "moment"; // add this 1 of 4
import * as moment from 'moment-timezone';

// import 'moment-timezone';

import { UserService } from "src/app/core/services/user.service";
import { livetvService } from "src/app/views/livetv/livetv.servies";

@Component({
  selector: "app-epgslider",
  templateUrl: "./epgslider.component.html",
  styleUrls: ["./epgslider.component.css"],
  providers: [livetvService, UserService],
})
export class EpgsliderComponent implements OnInit, OnDestroy  {
  epgsliderData: any;
  epgPrograms = [];
  epgDataByDay: any;
  time_frame_width: number;
  epgRowData: any;
  @Input() episode_Deatil: any;
  @Output("getProgramDetail") getProgramDetail: EventEmitter<any> = new EventEmitter();
  elementOwlNext: HTMLElement = document.getElementsByClassName(
    "owl-next"
  )[1] as HTMLElement;

  sortedProgramList: void;
  activeProgramIndex: any;
  fullEpgArray: any;
  activeProgramDom: any;
  tempInterval: any;
  activeDay: any;
  ordinalDate: string;
  defaultTimezone:any='Asia/Calcutta';
  owlStageWIdth: number;
  totalEpgDuration: number;
  // owlElement:any = CarouselComponent;
  @ViewChild('owlElement') owlElement: CarouselComponent;

  constructor(
    public assetsService: AssetsService,
    public UserService: UserService,
    public livetvService: livetvService
  ) {
    this.setDefaultTimezone();
    console.log(moment().format('YYYY-MM-DD HH:mm a'))
    console.log(moment.tz.guess());
    console.log(this.owlElement);
  }
  
  // @ViewChild('owlElement', owlElement: CarouselComponent;


  days_owlOption = customOptionsForDaysSlider;
  time_owlOption = customOptionsForTimeSlider;

  data = {
    assets: [{}, {}, {}, {}, {}, {}, {}],
  };

  ngOnInit(): void {
    this.episode_Deatil = this.episode_Deatil;
    console.log("interval"+this.tempInterval);
    if (this.tempInterval) {
      clearInterval(this.tempInterval);
    }
    this.owlStageWIdth = 0;
  }

  ngOnDestroy() {
    console.log("interval"+this.tempInterval);
    if (this.tempInterval) {
      clearInterval(this.tempInterval);
    }

  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.tempInterval) {
      clearInterval(this.tempInterval);
    }

    this.episode_Deatil = changes.episode_Deatil.currentValue;
    console.log(this.episode_Deatil);
    this.getEpgData();

  }
  ngAfterViewInit() {
    try {
      this.time_frame_width =
        document.getElementsByClassName("_parent_time_frame")[0].clientWidth *
        2;
        this.getEpgData();

    } catch (e) {}
  }

  modifyEpgRowData(data) {
    return data.map((v) => ({ ...v, nextSlideStatus: true }));
  }

  getEpgData() {
    let programDateArr = [];
    let tempLiveData = {
      no_of_days: 7,
      plateform: "mobile_app",
      catlogue: CatlougeTypeConstants.catlogue,
      from_date: moment().format("YYYY-MM-DD"),
      channel_code: this.episode_Deatil["channel_code"],
      epg_id: this.episode_Deatil["epg_id"],
      code: this.episode_Deatil["code"],
      access_type: "web",
    };
    console.log(tempLiveData);
    let urlEncodedRecomdedParams = this.UserService.convertoJSONToUrlEncoded(
      tempLiveData
    );
    this.livetvService
      .getEpgData(urlEncodedRecomdedParams)
      .subscribe((data) => {
        console.log(data.result);
        this.epgRowData = this.modifyEpgRowData(data.result);
        this.epgPrograms = [];

        let i =0;

        this.epgRowData.forEach((element) => {
          programDateArr.push(
            moment.unix(element.start_timestamp).format('Do MMM YYYY')
          );
        });
        let programDateUniqArr = _.uniq(programDateArr);

        programDateUniqArr.forEach((element, index) => {
          // console.log(index, programDateUniqArr.length - 1);
          let progDay = "";
          progDay = element;
          if (index == 0) {
            progDay = `Today`;
          } else if (index == 1) {
            progDay = `Tomorrow`;
          } else {
            progDay = element;
          }

          this.epgPrograms.push({
            day: progDay,
            program: this.epgRowData.filter(
              (program) =>
                element ==
                moment.unix(program.start_timestamp).format('Do MMM YYYY')
            ),
          });
          // console.log(this.epgPrograms);
          if (index == programDateUniqArr.length - 1) {
            console.log(this.epgPrograms);
            // this.epgPrograms;
            this.setEpgDataDayWise("Today");
          }
        });
      });
  }

  setEpgDataDayWise(day) {
    this.activeDay = day;
    this.epgDataByDay = [];
    let tempFullEpgArr = this.epgPrograms.filter(
      (program) => day == program.day
    )[0].program;
    let activeIndex = this.getCurrent_program_index(tempFullEpgArr);
    clearInterval(this.tempInterval);

    if (activeIndex != -1) {
      // let beforeIndexArr = tempFullEpgArr.slice(0, activeIndex);
      let afterIndexArr = tempFullEpgArr.slice(
        activeIndex,
        tempFullEpgArr.length
      );
      // this.epgDataByDay = afterIndexArr.concat(beforeIndexArr);
      this.epgDataByDay = afterIndexArr;
      this.activeProgramIndex = this.getCurrent_program_index(
        this.epgDataByDay
      );
      console.log(this.epgDataByDay);
        setTimeout(()=>{
          document.getElementById('auto_live0').style.setProperty("left", `0px`, "!important");
        }, 1000);

      this.tempInterval = setInterval(() => {

        if (this.activeProgramIndex != -1) {
          this.getPercentageWidth(this.epgDataByDay)
          let current_timestamp = moment().unix()

          let curr_prog = this.epgDataByDay[this.activeProgramIndex];
          if (
            (this.epgDataByDay[this.activeProgramIndex].nextSlideStatus === true) &&
            ( current_timestamp > curr_prog.end_timestamp)
          ) {

            document.getElementById('auto_live'+this.activeProgramIndex).style.setProperty("left", `0px`, "important");
            this.getProgramDetail.emit();

            let elementOwlNext = document.getElementsByClassName(
              "owl-next"
            )[1] as HTMLElement;
            elementOwlNext.click();
            // console.log("nextSlideStatus");
            this.epgDataByDay[this.activeProgramIndex].nextSlideStatus = false;
              // console.log(this.activeProgramIndex);
            this.activeProgramIndex++;
          }
        }
      }, 1000);
    } else {
      this.epgDataByDay = tempFullEpgArr;
    }
    this.activeProgramIndex = this.getCurrent_program_index(this.epgDataByDay);
    this.owlElement.to('0');

  }

  set_moment(time) {
    return  moment.unix(time).format('hh:mm A')
  }

  getCurrent_program_index(programs) {

    const current_timestamp = moment().unix();
    return programs.findIndex(function (prog, index) {
      if (
        current_timestamp > prog.start_timestamp &&
        current_timestamp < prog.end_timestamp
      ) {
        return true;
      }
    });
  }

  getPercentageWidth(data) {
    let activeProgramIndex = this.getCurrent_program_index(
      data
    );
    if(activeProgramIndex != -1) {
        let curr_prog = data[activeProgramIndex];
        let  currentTime = moment().unix();

        let startTime = curr_prog.start_timestamp;
        let differenceInseconds = currentTime - startTime;
        let current_activeClass = document.getElementById('slider_arrow'+activeProgramIndex).className;
        let activeClassWIdth = document.getElementsByClassName(current_activeClass)[0].clientWidth;
        let arrowWidth = 7;
        if( Math.round(differenceInseconds/60) < 5 ) {
          document.getElementById('auto_live'+activeProgramIndex).style.setProperty("left", `${arrowWidth}px`, "important");
        } else {
          let timespand = Math.round(differenceInseconds/60)*4;
          document.getElementById('auto_live'+activeProgramIndex).style.setProperty("left", `${timespand-arrowWidth}px`, "important");
        }
    }
  }

    setDefaultTimezone() {
      moment.tz.setDefault('Asia/Calcutta');
    }


    getOwlStageItemTotalWIdth(){
      let owlStags = document.getElementsByClassName('owl-item')
      this.owlStageWIdth = 50;
      console.log(owlStags);
      for (let i = 0; i < owlStags.length; i++) {
        console.log(document.getElementsByClassName('owl-item')[i].clientWidth);
        this.owlStageWIdth += document.getElementsByClassName('owl-item')[i].clientWidth;
      }
      document.getElementsByClassName("owl-stage")[1]['style'].width=(this.owlStageWIdth+"px");
    }
    currentActiveProgram() {}

    trackByFn(index, item) {
      return index;
    }

}
