<section class="my_subscription">
    <div class="container">
        <div class="row" *ngIf="!isTransactionSuccess && !isTransactionFailed">
            <div class="col-sm-12">
                <h4 class="accmain-head">Subscription</h4>

                <!-- Choose plan -->

                <div class="_choose_plan">
                    <ul>
                        <li class="active">
                            <p>Choose Plan</p>
                            <span class="_S_circle"></span>
                        </li>
                        <li class="make_payment">
                            <p>Make Payment</p>
                            <span class="_S_circle"></span>
                        </li>
                        <li>
                            <p>Confirmation</p>
                            <span class="_S_circle"></span>
                        </li>
                    </ul>
                </div>

                <!-- Apply coupon -->
                <div class="_subscription_p text-center coupon_code">
                    <div class="_subscription_p_d ">

                        <p>Please purchase any one of the following packs to watch this Movie/Series/Content and more.
                        </p>
                        <div class="_s_promo_code" >
                            <form class="_promo_form" [formGroup]="addPromoForm" (ngSubmit)="applypromoCode()">
                                <div class="form-group">
                                    <input type="text" formControlName="promocode" autocomplete="off"
                                        class="form-control" placeholder="Have a code?" required>
                                    <button class="_t_btn">Apply now</button>
                                </div>
                                <p class="error error-height">
                                    <span style="color: red;" *ngIf="promocode_error != ''"> {{promocode_error}}</span>
                                </p>

                            </form>
                            <!-- <form class="_btn_form">
                                <button class="_p_btn" (click)="continueButton()">Continue</button>
                            </form> -->

                        </div>
                    </div>
                </div>

                <!-- pack list -->
                <div class="nopack" *ngIf="groupPack.length == 0">{{noPackFound}}</div>
                
                <div *ngIf="groupPack.length > 0">

                    <div *ngFor="let group of groupPack;let indexJS=index;" class="_subscription_p">

                        <!-- group side bar start -->
                        <div *ngIf="countObjectKey(group) > 1" class="_subscription_amount" id="_subscription_amount">
                            <h2>{{group.name}}</h2>
                            <span class="subscription_text_bg">
                                {{group.PMV_CHANNEL_LIST}}
                            </span>

                            <ul>
                                <li *ngFor="let gDesc of group.GROUP_DESCRIPTION">
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                    <p>{{gDesc}}</p>
                                </li>
                            </ul>
                        </div>
                        <!-- group side bar end -->


                        <!-- list of group packs start -->
                        <div class="_subscription_p_d">
                            <div class="_subscription_package_nouse">
                                <div class="_subscription_package" *ngFor="let pack of group.packs;let index=index;">
                                    <div class="_option_box_shadow "
                                        *ngIf=" countObjectKey(pack) > 0 && pack.PM_PRICE != ''">

                                        <input type="radio" name="rentSelectedCode" [value]="pack.PM_CHARGECODE"
                                            [checked]="pack.PM_CHARGECODE == selectedPack.PM_CHARGECODE && selectedGroup?.code === group.code"
                                            (change)="onRentItemChange($event.target.value,indexJS, pack, group)">
                                        <div class="_amount_data">
                                            <span class="plan_bg">{{pack.PMV_VAL_DES}}</span>
                                            <div class="_img_tik">
                                                <img src="/assets/img/right_tik.png">
                                            </div>
                                            <div class="_plan_list">
                                                <div class="_paln_price_align">
                                                    <div class="_r_value _off_price " *ngIf="pack.PCH_IS_DISCOUNT > 0">
                                                        <img src="/assets/img/rupees_light.png">
                                                        <h2>
                                                            {{pack.PCH_IS_DISCOUNT}}
                                                        </h2>
                                                    </div>
                                                    <div class="_r_value">
                                                        <img src="/assets/img/rupees.png">
                                                        <h2>
                                                            {{pack.PM_PRICE}}
                                                        </h2>
                                                    </div>
                                                </div>
                                                <h6>
                                                    Validity : {{pack.PM_VALIDITY}}
                                                    {{pack.PM_VALIDITY == '1' ? 'day' :
                                                    'days'}}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="_s_promo_code pull-right">
                                <form class="_btn_form">
                                    <button class="mr15 _p_btn" [disabled]="selectedIndex != indexJS"
                                        (click)="continueButtons()">Continue</button>
                                </form>
                            </div>


                            <!-- Select_final plan -->
                            <div *ngFor="let pack of group.packs;let index=index;">
                                <div *ngIf="rentContinuePressed && selectedRentChargeCode == pack.PM_CHARGECODE && selectedGroup?.code === group.code">
                                    <div class="_subscription_payment">

                                        <div class="_subscription_second">
                                            <div class="_plan_selection">
                                                <div class="_amount_data">
                                                    <span class="plan_bg">{{pack.PMV_VAL_DES}}</span>
                                                    <div class="_plan_list confirm_plan_margin_top">
                                                        <div class="_r_value">
                                                            <!-- <small *ngIf="isCouponApplied" class="striked-price text-white">₹{{ selectedPack.PM_PRICE }}</small> -->
                                                            <img src="/assets/img/rupees.png">
                                                            <h2>
                                                                {{ isCouponApplied ? appliedCoupon?.final_price : selectedPack.PM_PRICE }}
                                                            </h2>
                                                        </div>
                                                        <h6>
                                                            Validity : {{pack.PM_VALIDITY}}
                                                            {{pack.PM_VALIDITY == '1' ? 'day' :
                                                            'days'}}
                                                        </h6>

                                                        <mat-accordion class="coupon-price-seemore-icon"
                                                            *ngIf="isCouponApplied">
                                                            <mat-expansion-panel>
                                                                <mat-expansion-panel-header>
                                                                    <mat-panel-title></mat-panel-title>
                                                                </mat-expansion-panel-header>
                                                                <div class="coupon-price-seemore">
                                                                    <label>Pack Price</label>
                                                                    <span>₹{{ selectedPack.PM_PRICE }}</span>
                                                                </div>
                                                                <div class="coupon-price-seemore">
                                                                    <label>Coupon Discount</label>
                                                                    <span>₹{{ appliedCoupon?.discount_price }}</span>
                                                                </div>
                                                                <div class="coupon-price-seemore" style="background-color: #34323F;">
                                                                    <label>Amount Payable</label>
                                                                    <span>₹{{ appliedCoupon?.final_price }}</span>
                                                                </div>
                                                            </mat-expansion-panel>
                                                        </mat-accordion>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- payment options -->

                            <div *ngFor="let pack of group.packs;let index=index;">
                                <div *ngIf=" countObjectKey(pack) > 0  && pack.PM_PRICE != '' ">

                                    <div *ngIf="selectedRentChargeCode == pack.PM_CHARGECODE && selectedGroup?.code === group.code && rentContinuePressed">

                                        <div *ngIf="!isRazorpayPayment" class="_subscription_payment _select_option_m">
                                          <p class="choose_payment"
                                                *ngIf="pack.PACK_TYPE && pack.PACK_TYPE.length >= 1">
                                                Please select a payment method</p>
                                            <div class="_subscription_card">
                                                <div class="payment_detail">
                                                    <form (submit)="placeOrder()">

                                                        <div class="form-group">
                                                            <div class="form-group"
                                                                *ngFor="let paymode of pack.PAYMENT_MODES">
                                                                <label>
                                                                    <input #paymentGatewayRadio type="radio" [id]="paymode.PM_CHARGECODE"
                                                                        name="selectedRentPayment"
                                                                        class="selectedRentPayment"
                                                                        (change)="onPaymentSelected(paymode.PM_PRICE, paymode, paymode.PM_VALIDITY)">
                                                                    <div class="_img_tik">
                                                                        <img src="/assets/img/right_tik.png">
                                                                    </div>
                                                                    {{paymode.PACK_TYPE}}
                                                                    <p class="payment_mode_info">
                                                                        {{paymode.PAYMENT_MODE_TEXT}}</p>
                                                                    <img class="paymode"
                                                                        *ngIf="strToLower(paymode.PACK_TYPE)"
                                                                        src="{{paymode.PCH_IMG}}">
                                                                    <!-- <img class="paymode"
                                                                        *ngIf="strToLower(paymode.PCH_PUR_MODE_DESC) == 'upi'"
                                                                        src="{{paymode.PCH_IMG}}">
                                                                    <img class="paymode"
                                                                        *ngIf="strToLower(paymode.PCH_PUR_MODE_DESC) == 'cards/nb/others'"
                                                                        src="{{paymode.PCH_IMG}}">
                                                                    <img class="paymode"
                                                                        *ngIf="strToLower(paymode.PCH_PUR_MODE_DESC) == 'razorpay'"
                                                                        src="{{paymode.PCH_IMG}}">

                                                                    <img class="paymode"
                                                                        *ngIf="strToLower(paymode.PCH_PUR_MODE_DESC) == 'credit/debit/upi'"
                                                                        src="{{paymode.PCH_IMG}}"> -->

                                                                </label>
                                                            </div>

                                                            <div *ngIf="!selectedRentPayment" class="help-block error">
                                                                {{details_error}}</div>
                                                        </div>


                                                        <div class="_pay_btn_s">
                                                            <button class="_p_btn_custom"
                                                                *ngIf="selectedRentChargeCode != ''  && rentContinuePressed"
                                                                [disabled]="payButtonText != 'PAY NOW'">{{payButtonText}}</button>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div *ngIf="isRazorpayPayment"> -->
                                            <div class="razorpay_sdk"
                                                *ngIf="isRazorpayPayment"> <!-- isPackSelected &&  -->
                                                <h6 class="text-white">
                                                    <a (click)="handleBackClick()"><img src="../../../../assets/img/Icon/back-arrow.svg" /></a>
                                                    Please select a billing option
                                                </h6>
                                
                                                <!-- <div class="subscribe-pack-info">
                                                    <div class="subscribe-pack-text">
                                                        <span class="subscribe-pack-name">
                                                            <label>{{ selectedPack.PM_DESCRIPTION }}</label>
                                                            <span>Validity : {{ selectedPack.PM_VALIDITY > 1 ? selectedPack.PM_VALIDITY + ' days' : selectedPack.PM_VALIDITY + ' day' }}</span>
                                                        </span>
                                                        <span class="subscribe-pack-price">
                                                            <span>{{ selectedPack.PMV_VAL_DES }}</span>
                                                            <label>
                                                                <small *ngIf="selectedPack.PCH_IS_DISCOUNT > 0" class="striked-price">{{ selectedPack.PMV_SYMBOL }} {{ selectedPack.PCH_IS_DISCOUNT }}</small>
                                                                <label style="display: inline;">{{ selectedPack.PMV_SYMBOL }} {{ isCouponApplied ? appliedCoupon?.final_price : selectedPack.PM_PRICE }}</label>
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <p *ngIf="selectedPack.PM_AUTORENEWFLAG === 'Y'">
                                                        You will be automatically* charged {{ selectedPack.PMV_SYMBOL }} {{ selectedPack.PM_PRICE }} after every {{ selectedPack.PM_VALIDITY > 1 ? selectedPack.PM_VALIDITY + ' days' : selectedPack.PM_VALIDITY + ' day' }} until you cancel
                                                    </p>
                                                </div> -->
                                
                                                <div data-toggle='modal' data-target='#applyCoupon' class="subscribe-coupon"
                                                    *ngIf="!isCouponApplied">
                                                    <span class="coupon-star">%</span>
                                                    <label>APPLY COUPON</label>
                                                    <img src="../../../../assets/img/Icon/back-arrow.svg" />
                                                </div>
                                                
                                                <div class="subscribe-coupon"
                                                    *ngIf="isCouponApplied">
                                                    <img src="../../../../assets/img/Icon/coupon-applied.svg" alt="" style="transform: rotate(0deg);margin-right: 5px;" />
                                                    <label>You saved ₹{{ appliedCoupon?.discount_price }} with "{{ appliedCoupon?.coupon_code }}"</label>
                                                    <small>Coupon Applied Successfully</small>
                                                    <!-- <img src="../../../../assets/img/Icon/close.svg"  data-toggle="modal" data-target="#removeCouponModal"/> -->
                                                    <img src="../../../../assets/img/Icon/close.svg"  (click)="removeCoupon()" />
                                                </div>
                                
                                                <div class="subscribe-payment">                              
                                                    <mat-accordion class="subscribe-accordion" style="color: #fff;">
                                                        <mat-expansion-panel class="subscribe-payment-bg subscribe-payment-choose"
                                                            *ngFor="let paymentMode of paymentModes"
                                                            > <!-- (click)="selectPaymentMode(paymentMode)" -->
                                                            <mat-expansion-panel-header>
                                                                <mat-panel-title>
                                                                    <img *ngIf="paymentMode.image" [src]="paymentMode.image" style="height: 20px; margin-right: 10px;" />
                                                                    {{ paymentMode.mode }}
                                                                </mat-panel-title>
                                                            </mat-expansion-panel-header>
                                                            <ng-container [ngSwitch]="strToLower(paymentMode.mode)">
                                                                <div *ngSwitchCase="strToLower('Debit/Credit Cards')">
                                                                    <ul class="card-pay-choose" *ngIf="!selectedCardType">
                                                                        <li (click)="showCardFrom('Debit')">
                                                                            <a href="javascript:;">
                                                                                <img src="../../../../assets/img/Icon/card-icon.svg" />
                                                                                <p>Debit Card</p>
                                                                            </a>
                                                                        </li>
                                                                        <li (click)="showCardFrom('Credit')">
                                                                            <a href="javascript:;">
                                                                                <img src="../../../../assets/img/Icon/card-icon.svg" />
                                                                                <p>Credit Card</p>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                    <div class="paymet-card" *ngIf="selectedCardType">
                                                                        <h6 class="text-white">
                                                                            <a href="javascript:;">
                                                                                <img src="../../../../assets/img/Icon/back-arrow.svg"
                                                                                    (click)="hideCardForm()"/>
                                                                            </a>
                                                                            Enter Your {{ selectedCardType }} Card Details
                                                                        </h6>
                                                                        <form class="card-payment" autocomplete="off"
                                                                            [formGroup]="cardForm"
                                                                            (ngSubmit)="onCardFormSubmit()">
                                                                            <div class="form-group mb-0">
                                                                                <div class="card-number">
                                                                                    <label>Card Number</label>
                                                                                    <input class="form-control" type="text" placeholder="Enter The Card Number Here"
                                                                                        maxlength="19"
                                                                                        formControlName="number"
                                                                                        (keydown)="preventNonNumeric($event)"
                                                                                        (input)="onCardNumberChange($event)"/>
                                                                                </div>
                                                                                <div class="popup-error-height text-left">
                                                                                    <!-- <app-error
                                                                                        *ngIf="isCardFormSubmitted && cardForm.controls?.number.invalid"
                                                                                        message="Please enter valid card number"></app-error> -->
                                                                                        <small *ngIf="isCardFormSubmitted && cardForm.controls?.number.invalid">Please enter valid card number</small>
                                                                                </div>
                                                                            </div>
                                                                            <div class="form-group mb-0">
                                                                                <div class="row">
                                                                                    <div class="col-md-5">
                                                                                        <div class="card-expiry">
                                                                                            <label>Expiry</label>
                                                                                            <input class="form-control" type="text" placeholder="MM/YY"
                                                                                                maxlength="5"
                                                                                                formControlName="expiry"
                                                                                                (keydown)="preventNonNumeric($event, true)"
                                                                                                (input)="onCardExpiryChange($event)"/>
                                                                                        </div>
                                                                                        <div class="popup-error-height text-left">
                                                                                            <!-- <app-error
                                                                                                *ngIf="isCardFormSubmitted && cardForm.controls?.expiry.invalid"
                                                                                                message="Please enter a valid expiry date"></app-error> -->
                                                                                                 <!-- (cardForm.controls?.expiry.hasError('required') ||  -->
                                                                                            <small *ngIf="isCardFormSubmitted && cardForm.controls?.expiry.invalid">Please enter a valid expiry date</small>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-7">
                                                                                        <div class="card-cvv">
                                                                                            <label>CVV</label> 
                                                                                            <input class="form-control" type="password" name="new-password" autocomplete="new-password" placeholder="***"
                                                                                                maxlength="3"
                                                                                                (keydown)="preventNonNumeric($event)"
                                                                                                formControlName="cvv"/>
                                                                                        </div>
                                                                                        <div class="card-cvv-text">
                                                                                            <small>3 digits CVV on the back of the card.</small>
                                                                                        </div>
                                                                                        <div class="popup-error-height text-left">
                                                                                            <!-- <app-error
                                                                                                *ngIf="isCardFormSubmitted &&cardForm.controls?.cvv.invalid"
                                                                                                message="Please enter valid 3 digit CVV number"></app-error> -->

                                                                                                <small *ngIf="isCardFormSubmitted &&cardForm.controls?.cvv.invalid">Please enter valid 3 digit CVV number</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                            <div class="form-group mb-0">
                                                                                <div class="card-number">
                                                                                    <label>Card Holder Name</label>
                                                                                    <input class="form-control" type="text"  placeholder="Enter The Card Holder Name"
                                                                                        formControlName="name"/>
                                                                                </div>
                                                                                <div class="popup-error-height text-left">
                                                                                    <!-- <app-error
                                                                                        *ngIf="isCardFormSubmitted && (cardForm.controls?.name.hasError('required') || cardForm.controls?.name.invalid)"
                                                                                        message="Please enter a valid Name"></app-error> -->

                                                                                        <small *ngIf="isCardFormSubmitted && (cardForm.controls?.name.hasError('required') || cardForm.controls?.name.invalid)">Please enter a valid Name</small>
                                                                                </div>
                                                                            </div>
                                                                            <div class="form-group mt-4">
                                                                                <button type="submit" class="_p_btn_custom"
                                                                                    *ngIf="!isTransactionProcessing"
                                                                                    > <!-- [disabled]="cardForm.invalid" -->
                                                                                    Pay ₹ {{ isCouponApplied ? appliedCoupon?.final_price : selectedPack.PM_PRICE }}
                                                                                </button>
                                                                                <div class="payment-animation" *ngIf="isTransactionProcessing"></div>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <ul class="card-payment-thumb">
                                                                                    <li><img src="../../../../assets/img/Icon/visa.svg" /></li>
                                                                                    <li><img src="../../../../assets/img/Icon/master.svg" /></li>
                                                                                    <li><img src="../../../../assets/img/Icon/american-express.svg" /></li>
                                                                                    <li><img src="../../../../assets/img/Icon/rupay.svg" /></li>
                                                                                </ul>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                
                                                                <div *ngSwitchCase="strToLower('Net Banking')">
                                                                    <form [formGroup]="netBankingForm"
                                                                        (submit)="payWithNetBanking()">
                                                                        <div class="additional_select bank-select">
                                                                            <ng-select class="form-control"
                                                                                formControlName="bank"
                                                                                [searchable]="true"
                                                                                [clearable]="false"
                                                                                placeholder="Select Bank"
                                                                                [items]="netBankingArray"
                                                                                (change)="onBankChange($event)"
                                                                                bindLabel="title" bindValue="code"> <!-- paymentMode[paymentMode.type] -->
                                                                                <ng-template ng-label-tmp let-item="item">
                                                                                    <img *ngIf="item.image" [src]="item.image" />
                                                                                    <span class="no-bank-img" *ngIf="!item.image"></span>
                                                                                    <span>{{ item.title }}</span>
                                                                                </ng-template>
                                    
                                                                                <ng-template ng-option-tmp let-item="item">
                                                                                    <img *ngIf="item.image" [src]="item.image" />
                                                                                    <span class="no-bank-img" *ngIf="!item.image"></span>
                                                                                    <span>{{ item.title }}</span>
                                                                                </ng-template>
                                                                            </ng-select>
                                                                            <div class="bank-space"></div>
                                                                        </div>
                                                                        
                                                                        <button type="submit" class="_p_btn_custom mt-4" *ngIf="!isTransactionProcessing">Submit</button>
                                                                        <div *ngIf="isTransactionProcessing"><div class="payment-animation"></div></div>

                                                                        
                                                                    </form>
                                                                </div>
                                                                <div *ngSwitchCase="strToLower('UPI')">
                                                                    <div class="upi-payment">
                                                                        <div class="upi-qr">
                                                                            <img src="../../../../assets/img/Icon/qr.svg" />
                                                                            <div class="qr-btn hide-qr">
                                                                                <button class="_p_btn_custom"
                                                                                    (click)="payWithUpi('qr')">Show QR Code</button>
                                                                            </div>
                                                                        </div>
                                                                        <div class="upi-field">
                                                                            <label class="text-white" style="padding: 0;">Open your UPI app and scan QR code to pay</label>
                                
                                                                            <ul class="upi-icon mt-2">
                                                                                <li><img src="../../../assets/img/Icon/paytm.svg" /></li>
                                                                                <li><img src="../../../assets/img/Icon/phonepe.svg" /></li>
                                                                                <li><img src="../../../assets/img/Icon/google-pay.svg" /></li>
                                                                                <li><img src="../../../assets/img/Icon/upi.svg" /></li>
                                                                            </ul>
                                                                            
                                                                            <span class="or_line qr-line">
                                                                                <p>OR</p>
                                                                            </span>
                                
                                                                            <form (submit)="payWithUpi('collect')">
                                                                                <div class="form-group mb-0">
                                                                                    <input autocomplete="off" placeholder="Enter Your UPI ID" type="text" class="form-control" name="vpa"
                                                                                        [(ngModel)]="upiVpa" />
                                                                                </div>
                                                                                <div class="form-group mb-0"
                                                                                    *ngIf="isPackRenewable">
                                                                                    <label style="padding-bottom: 0;">
                                                                                        <input placeholder="Enter Your UPI ID" type="checkbox" class="form-control" name="renew-confirm"
                                                                                        [(ngModel)]="isRenewChecked"/> <!--  -->
                                                                                        <small style="font-size: 10px;">You will be automatically charged ₹{{ selectedPack.PM_PRICE }} after every {{ selectedPack.PM_VALIDITY > 1 ? selectedPack.PM_VALIDITY + ' days' : selectedPack.PM_VALIDITY + ' day' }}</small>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="popup-error-height text-left">
                                                                                   <small>{{ upiError }}</small>
                                                                                    <!-- <app-error
                                                                                        *ngIf="upiError"
                                                                                        [message]="upiError"></app-error> -->
                                                                                </div>
                                                                                <button type="submit" class="_p_btn_custom" *ngIf="!isTransactionProcessing">Verify and Pay Now</button>
                                                                                <div class="payment-animation" *ngIf="isTransactionProcessing"></div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <button (click)="createQrCode()">create button</button> -->
                                                                </div>
                                                                <div *ngSwitchCase="strToLower('Wallet')">
                                                                    <ul class="wallet-payment">
                                                                        <li [ngClass]="{ 'active': wallet.code === selectedWallet?.code }"
                                                                            *ngFor="let wallet of walletsArray"
                                                                            (click)="selectWallet(wallet)">
                                                                            <a href="javascript:;">
                                                                                <img *ngIf="wallet.image" [src]="wallet.image" />
                                                                                <span class="no-wallet-img" *ngIf="!wallet.image">
                                                                                    <i></i>
                                                                                </span>
                                                                                <label>{{ wallet?.title || wallet.code }}</label>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                    <button type="submit" class="_p_btn_custom"
                                                                        (click)="payWithWallet()"
                                                                        *ngIf="!isTransactionProcessing">Submit</button>
                                                                    <div class="payment-animation" *ngIf="isTransactionProcessing"></div>
                                                                </div>
                                                            </ng-container>
                                                        </mat-expansion-panel>
                                                    </mat-accordion>
                                
                                                </div>
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- list of group packs end -->
                        <!-- <span style="color: #fff;">test {{groupPack.length}} {{indexJS}}</span> -->
                        <div class="_or" *ngIf="grouplength&&((grouplength-1) >indexJS)">
                            <p>or</p>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div *ngIf="isTransactionSuccess">
            <div class="text-center text-white" style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
                <h4>Transaction Successful</h4>
                
                <app-success-message class="space-bottom0"></app-success-message>
                
                <label>Thank you for subscribing to the Premium plan on Prideplex!</label>
                <div class="transaction-info">
                    <span>Payment ID <p>{{ transactionSuccessObj?.razorpay_payment_id }}</p></span>
                    <span>Order ID <p>{{ transactionSuccessObj?.razorpay_order_id }}</p></span>
                </div>
                <button class="_p_btn_custom"
                    routerLink="/">Home</button>
            </div>
            
        </div>

        <div *ngIf="isTransactionFailed">
            <div class="text-center text-white">
                <h4>Transaction Failed</h4>
                <app-transaction-failure></app-transaction-failure>
                <label>Unfortunately, we were unable to process your payment. Please try again or choose a different payment method.</label>

                <div class="text-center mt-3">
                <button style="float: none;" class="_p_btn_custom"
                    (click)="transactionFailureBackClick()">Back</button>
                    </div>
            </div>
        </div>
    </div>

    <!--Add promocode Success-->
    <a #add_promocodesuccess data-toggle="modal" data-target="#add_promocodesuccess"></a>
    <div class="modal auth" id="add_promocodesuccess" data-backdrop="static">
        <div class="modal-dialog">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" #closePromoButton>&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="success-add">
                        <img src="../../../assets/img/success.png">
                        <p>Promocode has been applied successfully.</p>
                    </div>

                    <div class="action-btn">
                        <!-- <div>
                            <button type="button" class="btn btn-primary yes" (click)="goToMyacoount()">Ok</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add promocode Success-->
</section>

<div class="modal" id="applyCoupon">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <button #closeApplyCoupon type="button" id="closeApplyCoupon" class="close" data-dismiss="modal"><img alt="" src="./assets/img/Icon/coupon-close.svg" /></button>
            </div>

            <div class="modal-body">
                <ng-container *ngIf="!isApplyCouponSuccess">
                    <div class="apply-coupon-box">
                        <form (submit)="applyCoupon({}, couponInputValue)">
                            <input type="text" class="form-control" placeholder="Type coupon code here"
                                (input)="searchCoupon($event)" />
                            <button type="submit">Apply</button>
                        </form>
                    </div>
                    <div class="popup-error-height text-left">
                        <small>{{ couponError }}</small>
                        <!-- <app-error
                            *ngIf="couponError"
                            [message]="couponError"></app-error> -->
                    </div>
                    <div class="apply-coupon-list"
                        *ngIf="currentCouponsArray?.length > 0">
                        <h6 class="text-white">Best offer for you</h6>
                        <ul>
                            <li *ngFor="let coupon of currentCouponsArray"
                                (click)="applyCoupon(coupon)">
                                <label>{{ coupon.coupon_details }}</label>
                                <span>{{ coupon.coupon_code }}</span>
    
                                <p>Tap To Apply</p>
                            </li>
                        </ul>
                    </div>
    
                    <div
                        *ngIf="currentCouponsArray?.length === 0">
                        <app-no-active-plan></app-no-active-plan>
                    </div>
                </ng-container>

                <div class="text-white text-center success-icon-height"
                    *ngIf="isApplyCouponSuccess">
                    <span>Code "{{ appliedCoupon?.coupon_code }}" applied successfully.</span>
                    <app-redeem-code-success></app-redeem-code-success>
                    <h6>You saved ₹{{ appliedCoupon?.discount_price }}</h6>
                    <span>with this code</span>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal auth popup_background w430" id="removeCouponModal">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body" style="padding: 0 20px;">
                <p class="text-white">Are you sure?</p>
        
                <div class="text-right mt-2">
                    <button class="_p_btn_custom ml-3"
                        data-dismiss="modal" data-target="#removeCouponModal">
                        <span>Cancel</span>
                    </button>
                    <button class="_p_btn_custom" data-dismiss="modal" data-target="#removeCouponModal"
                        (click)="removeCoupon()" >
                        <span>
                            Yes, Remove
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #loginPopup>
    <app-login></app-login>
</ng-template>