import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../account/account.service';
import { UserService } from 'src/app/core';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { CatlougeTypeConstants } from "src/app/core/constants";

@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.css']
})
export class RedeemComponent implements OnInit {

  @ViewChild('openLoginllModel') openLoginModel: ElementRef;
  @ViewChild('add_promocodesuccess') add_promocodesuccess;
  @ViewChild('add_promoCodeError') add_promoCodeError;
  @ViewChild('closebutton') closebutton;
  addRedeemForm: FormGroup;
  promocode_error: string = '';
  error_code: any;
  public isRedeemActive: boolean = false;
  public registeredBy: any;
  public registredEmail: any;
  public registredMobileNumber: any;
  public some_error: any;
  public isCodeFormSubmitted: boolean = false;
  catlogue = CatlougeTypeConstants.catlogue;
  public userUnique: any;
  public clicklink: any;


  constructor(private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    public fb: FormBuilder,
    private apiService: AccountService,
    private userService: UserService,
    private googleEvent: EventTrackingService) {

    this.addRedeemForm = this.fb.group({
      'code' : ['', Validators.required]
    });
  }

  ngOnInit(): void {
    //this.tes();
    // this.isRedeemActive = true;
    // this.profileDetails();
    if (navigator.userAgent.match(/Android/i)) {
      this.clicklink = "andorid";
    }

    if (navigator.userAgent.match(/iPhone/i)) {
      this.clicklink = "iphone";
    }

  }




  ngAfterViewInit(): void {
    this.checkUserIslogin();
  }

  checkUserIslogin() {
    if (localStorage.getItem('isAuthenticated') == undefined) {
      this.openLoginModel.nativeElement.click();
    } else {
      this.isRedeemActive = true;
      this.profileDetails();
    }
  }
  openLoginModal(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });

  }

  openSignupModal(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });

  }


  profileDetails() {
    this.apiService.getData('signupnexg/profile')
      .subscribe((data: any) => {
        if (Object.keys(data.result).length > 0) {
          this.registeredBy = data.result.register_by;
          this.registredEmail = data.result.emailaddress;
          this.registredMobileNumber = data.result.mobile;
        }

      },
        error => {
          this.some_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }


  get getCodeFormControl() {
    return this.addRedeemForm.controls;
  }

  addRedeemCode() {
    this.isCodeFormSubmitted = true;
    console.log(this.addRedeemForm);
    if (this.addRedeemForm.invalid) {
      return false;
    }

    this.userUnique = 'NA';
    if (this.registeredBy == 'mobile') {
      this.userUnique = this.registredMobileNumber;
    }
    if (this.registeredBy != 'mobile') {
      this.userUnique = this.registredEmail;
    }

    let appliedCode = this.addRedeemForm.controls.code.value;
    this.googleEvent.eventEmitter('Redeem', 'Redeem', appliedCode, 0 ,  this.userUnique,this.userUnique + ' - Redeem Page');

    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({ 'couponcode': this.addRedeemForm.controls.code.value, 'charge_code': '', 'catlogue': this.catlogue });
    this.apiService.postData('activationcode', urlEncodedBodyData)
      .subscribe((data: any) => {
       
        this.error_code = data['error_code'];
        if (data.error_code == '200') {
          // this.add_promocodesuccess.nativeElement.click(); // show success
         document.getElementById('con').style.display='none';
         document.getElementById('success-add').style.display='block';
          this.addRedeemForm.reset();
        } else {
          this.promocode_error = data.error_string;
          this.add_promoCodeError.nativeElement.click();
        }

      },
        error => {
          this.some_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }

  closePopup(){
    window.location.href = "/";
    this.closebutton.nativeElement.click();
  }

}

