import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { map } from 'rxjs/operators';
import { JwtService } from 'src/app/core';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class jwPlayerService {
userInfo:any;
catlouge:any;

public closePlayer = new BehaviorSubject(false);
  public closePlayerEvent:any;

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    public spinner:SpinnerVisibilityService
  ) { 
    this.closePlayerEvent = this.closePlayer.asObservable();
  }


    play(credentials){

      return this.apiService.post('analyticsstreaminglog',  credentials).pipe(map(data => {
        return data;
      }));

    }

    analyticsLog(credentials){
      this.spinner.hide();
      return this.apiService.post('analytics_wowza_streaminglog',  credentials).pipe(map(data => {
        return data;
      }));

    }

    pause(credentials){
      // alert('credentials pause')
      this.spinner.hide();
      return this.apiService.post('videoend',  credentials).pipe(map(data => {
          return data;
        }));
      }



    stop(credentials){
      return this.apiService.post('analyticsstreaminglog',  credentials).pipe(map(data => {
        return data;
      }));

    }

    complete(credentials){
      return this.apiService.post('videoend',  credentials).pipe(map(data => {
        return data;
      }));

    }


    removeFromContinueWatching(credentials){
      console.log(credentials);
      return this.apiService.post('videoend',  credentials).pipe(map(data => {
        return data;
      }));

    }

    sendNextEpisode(credentials){
      return this.apiService.post('videoend',  credentials).pipe(map(data => {
        return data;
      }));
      
    }
}
