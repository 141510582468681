import { Injectable } from '@angular/core';
import { Observable, throwError} from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class PaymentService {
  END_POINT = environment.api_url;
  constructor() { }

  
}
