import { Component, OnInit } from '@angular/core';
// import {  } from "sr";
@Component({
  selector: 'app-resend-otp',
  templateUrl: './resend-otp.component.html',
  styleUrls: ['./resend-otp.component.css']
})
export class ResendOtpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  requestOtp(){


  }

}
