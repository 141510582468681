import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-layout',
  templateUrl: './open-layout.component.html',
  styleUrls: ['./open-layout.component.css']
})
export class OpenLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }

}
