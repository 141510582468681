<h2 *ngIf="cateoryname">{{cateoryname}}</h2>
<div class="demo-thumb">
    <div class="row m5">
        <div class="col-md-3 p15"><img src="./assets/img/not-found/landscape.png" /></div>
        <div class="col-md-3 p15"><img src="./assets/img/not-found/landscape.png" /></div>
        <div class="col-md-3 p15"><img src="./assets/img/not-found/landscape.png" /></div>
        <div class="col-md-3 p15"><img src="./assets/img/not-found/landscape.png" /></div>
        <div class="col-md-3 p15"><img src="./assets/img/not-found/landscape.png" /></div>
        <div class="col-md-3 p15"><img src="./assets/img/not-found/landscape.png" /></div>
        <div class="col-md-3 p15"><img src="./assets/img/not-found/landscape.png" /></div>
        <div class="col-md-3 p15"><img src="./assets/img/not-found/landscape.png" /></div>
    </div>
</div>