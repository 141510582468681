<div class="modal-dialog">
    <div class="modal-content">
    
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Verify OTP</h4>
        <button type="button" class="close" data-dismiss="modal" #closebutton>&times;</button>
      </div>
      
      <!-- Modal body -->
      <div class="modal-body">
        <div *ngIf="verifySuccessMsg">
          <h2 style="color:#fff" class="_verify_t">{{verifySuccessMsg}}</h2>
        </div>

        <div *ngIf="!verifySuccessMsg">
          <form [formGroup]="verifyForm" (ngSubmit)="verifyOtp()">
            <div class="form-group">
              <input type="text" class="form-control" id="otp" formControlName="otp" id="otp"  placeholder="Enter Otp">
            </div>
            <div class="form-group">
              <input type="text" class="form-control" id="email" formControlName="mobile" id="email"  placeholder="Enter Mobile">
            </div>        
            <button type="submit" class="btn btn-default register">Confirm</button>
            </form>
        </div>
   
      </div>
      </div>
    </div>
